
























































































































import {
  computed, defineComponent, ref, PropType, reactive,
} from '@vue/composition-api';
import {
  ComboboxItem,
  IConditionalLogicAlarm,
  IConditionalLogicAlarmConditions, ITemplateSectionField, IVComboboxItem, LogicItemType,
} from '@/types';
import Store from '@/store';
import router from '@/router';

export default defineComponent({
  props: {
    isPublished: {
      type: Boolean,
      required: true,
    },
    currentField: {
      type: Object as PropType<ITemplateSectionField>,
      default: () => {},
      required: true,
    },
  },
  setup(props) {
    const savedCalculation = ref(false);
    const { templateId } = router.app.$route.params;
    const generateId = () => Math.floor(100000 + Math.random() + Math.random() * 50000000);
    const calcConditions = [
      {
        text: 'Add',
        value: '+',
      },
      {
        text: 'Subtract',
        value: '-',
      },
      {
        text: 'Multiply',
        value: '*',
      },
      {
        text: 'Divide',
        value: '/',
      },
    ];

    const currentCalcAlarm = reactive({}) as IConditionalLogicAlarm;

    const isLoading = computed(() => Store.getters['conditionalLogic/getLoading']);

    /**
     *  @summary default state type for our logic object
     *  @author EJ McVey
     */
    const calculateNumberLogic = ref(
      [
        {
          type: 'field',
          value: {
            text: '',
            value: '',
          },
          text: '',
        },
        {
          type: 'condition',
          value: {
            text: '',
            value: '',
          },
          text: '',
        },
        {
          type: 'field',
          value: {
            text: '',
            value: '',
          },
          text: '',
        },
      ],
    );

    /**
     *  @summary get all section number fields to display when a user is selecting fields to calculate
     *  @description filters any that are not number & returns array format for displaying in combox dropdown
     *  @author EJ McVey
     */
    const numberFields = computed(
      (): ITemplateSectionField[] => {
        const fields = Store.getters['templateCustomisation/getAllSectionsFields'];
        const getCurrentSelectedFields: number[] = calculateNumberLogic.value.map((logicItem) => parseInt(logicItem.value.value, 10));

        // Add only fields that are numbers & exclude the current field itself
        return fields.filter((field: ITemplateSectionField) => field.type === 'number'
          && field.id !== -1
          && field.id !== props.currentField.id
          && field.id !== undefined
          && !getCurrentSelectedFields.includes(field.id)).reduce((acc: IVComboboxItem[], prev: ITemplateSectionField) => {
          acc.push(
            {
              text: prev.name,
              value: prev.id,
            },
          );

          return acc;
        }, []);
      },

    );

    /**
     *  @summary on click event adding another condition to calculation
     *  @author EJ McVey
     */
    const addAnotherCondition = () => {
      calculateNumberLogic.value.push(
        {
          type: 'condition',
          value: {
            text: '',
            value: '',
          },
          text: '',
        },
        {
          type: 'field',
          value: {
            text: '',
            value: '',
          },
          text: '',
        },
      );
    };

    const clearLogicValues = () => {
      calculateNumberLogic.value = [
        {
          type: 'field',
          value: {
            text: '',
            value: '',
          },
          text: '',
        },
        {
          type: 'condition',
          value: {
            text: '',
            value: '',
          },
          text: '',
        },
        {
          type: 'field',
          value: {
            text: '',
            value: '',
          },
          text: '',
        },
      ];
      savedCalculation.value = false;
    };

    /**
     *  @summary Get all alarm data and find any alarms for current field
     *  @description filters any that are not number & returns array format for displaying in combox dropdown
     *  @author EJ McVey
     */
    const getCalculateLogic = () => {
      const conditionalAlarms = Store.getters['conditionalLogic/getData'];
      const currentAlarm = conditionalAlarms.filter((alarm: IConditionalLogicAlarm) => alarm.customFieldId === props.currentField.id);

      if (currentAlarm.length === 1 && currentAlarm[0].newValueLogic && currentAlarm[0].newValueLogic.length > 1) {
        Object.assign(currentCalcAlarm, {
          ...currentAlarm[0],
        });

        const currentCalc = currentAlarm[0].newValueLogic as LogicItemType[];

        calculateNumberLogic.value = [];
        currentCalc.forEach((calcItem: LogicItemType) => {
          const savedItem = {
            text: calcItem.text,
            type: calcItem.type === 'custom_field' ? 'field' : 'condition',
            value: {
              text: calcItem.text,
              value: calcItem.value.toString(),
            } as ComboboxItem,
          };

          calculateNumberLogic.value.push(savedItem);
        });

        savedCalculation.value = true;
      } else clearLogicValues();
    };

    getCalculateLogic();

    /**
     *  @summary save calculation in vuex & disable UI so no changes can be made
     *  @description  disables other tab & prepare to send to api when dialog closed
     *  @author EJ McVey
     */
    const addCalculateLogic = () => {
      const formattedNumberCalculation = calculateNumberLogic.value.map((logicItem: LogicItemType) => ({
        type: logicItem?.type === 'field' ? 'custom_field' : 'operator',
        value: logicItem?.value?.value || null,
        text: logicItem?.value?.text,
      }));

      const formattedNumberConditions = formattedNumberCalculation.filter((item) => item.type === 'custom_field').map((logicItem: any) => ({
        operator: 'AND',
        customFieldId: logicItem.value,
        criteria: 'not_empty',
        criteriaText: '',
        fieldName: '',
        fieldType: '',
        value: '',
      }));

      const conditionalLogicAlarm = {
        tempId: generateId(),
        customFieldId: props.currentField.id,
        name: '', // section name
        workOrderId: templateId,
        conditions: formattedNumberConditions as IConditionalLogicAlarmConditions[],
        populates: '',
        calculates: formattedNumberCalculation,
      };

      Object.assign(currentCalcAlarm, {
        ...conditionalLogicAlarm,
      });

      Store.dispatch('conditionalLogic/addConditionalLogicAlarm', conditionalLogicAlarm);
      savedCalculation.value = true;
    };

    /**
     *  @summary Remove saved calculation
     *  @description  removes from vuex & returns to default state
     *  @author EJ McVey
     */
    const removeLogic = () => {
      const objectToDelete = currentCalcAlarm;

      Object.assign(objectToDelete, {
        id: currentCalcAlarm.conditionId,
        eventId: currentCalcAlarm.id,
      });

      Store.dispatch('conditionalLogic/removeConditionalLogicAlarm', objectToDelete);

      clearLogicValues();
    };

    /**
     *  @summary Remove the added Pair from our main Logic array
     *  @description Build a new array removing the pair index (calculated by *2 for position) and spread into our main array
     *  @param {number} indexOfPair = the index of the pair within our 'getPairsOfLogic' array.
     *  @author EJ McVey
     */
    const onDeleteCalcLine = (indexOfPair: number) => {
      const extraCalculations = calculateNumberLogic.value.slice(3);

      const currIdx = indexOfPair === 1 ? 2 : indexOfPair * 2;
      const endIdx = currIdx + 1;

      // create new array with items up to index, and items from after the endIdx/pair
      const removePair = [...extraCalculations.slice(0, currIdx), ...extraCalculations.slice(endIdx + 1)];

      // spread the updated 2 arrays into 1 array and reassign it to our logic
      calculateNumberLogic.value = [
        ...calculateNumberLogic.value.slice(0, 3),
        ...removePair,
      ];
    };

    /**
     *  @summary Set up additional chains in pairs of { fieldName & condition (ie +/-)}
     *  @description This is for any additional logic after first field + condition + field to display nicely in UI
     *  @author EJ McVey
     */
    const getPairsOfLogic = computed(() => {
      if (!(calculateNumberLogic.value.length > 3)) return [];

      const arrayToPair = calculateNumberLogic.value.slice(3);

      return arrayToPair.reduce((acc: any, prev: LogicItemType, index: number, array: LogicItemType[]) => {
        if (index % 2 === 0) {
          acc.push(array.slice(index, index + 2));
        }
        return acc;
      }, []);
    });

    // Disable save button if all fields not filled in with value
    const validateCalculation = computed(() => calculateNumberLogic.value.every((logicItem) => logicItem.value.text));

    return {
      isLoading,
      calcConditions,
      calculateNumberLogic,
      numberFields,
      addAnotherCondition,
      addCalculateLogic,
      savedCalculation,
      removeLogic,
      getPairsOfLogic,
      clearLogicValues,
      getCalculateLogic,
      validateCalculation,
      onDeleteCalcLine,
    };
  },
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":"90%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"my-4 py-6 template-edit__change-log-button",attrs:{"block":"","outlined":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" Change Log ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"audit-trail__card",attrs:{"height":"80vh"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.templateName)+" Audit Trail ")]),_c('v-card-text',{staticClass:"audit-trail__card-text"},[_c('v-data-table',{staticClass:"\n            audit-trail__data-table\n            fill-height\n            d-flex\n            flex-column\n            justify-space-between\n          ",attrs:{"headers":_vm.headers,"items":_vm.auditTrailItems},scopedSlots:_vm._u([{key:"header.field",fn:function(ref){
var header = ref.header;
return [_c('v-text-field',{staticClass:"d-inline-block font-weight-regular",attrs:{"data-column-filter":"field","clearable":"","label":header.text},model:{value:(_vm.filterObject.field),callback:function ($$v) {_vm.$set(_vm.filterObject, "field", $$v)},expression:"filterObject.field"}})]}},{key:"header.action",fn:function(ref){
var header = ref.header;
return [_c('v-text-field',{staticClass:"d-inline-block font-weight-regular",attrs:{"data-column-filter":"action","value":header.text,"readonly":""}})]}},{key:"header.user",fn:function(ref){
var header = ref.header;
return [_c('v-text-field',{staticClass:"d-inline-block font-weight-regular",attrs:{"data-column-filter":"user","clearable":"","label":header.text}})]}},{key:"header.date",fn:function(ref){
var header = ref.header;
return [_c('v-text-field',{staticClass:"d-inline-block font-weight-regular",attrs:{"data-column-filter":"date","value":header.text,"readonly":""}})]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.date).toLocaleString().replace(',', '')))])]}}],null,true)})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"id":"closeAuditTrailBtn","color":"primary","text":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" Close ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
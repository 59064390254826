



















































import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    inboundMapping: {
      type: String,
      default: '',
    },
    inboundOverwrite: {
      type: Boolean,
    },
    outboundMapping: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const getInboundCheck = !!(props.inboundMapping && props.inboundMapping.length);
    const getOutboundCheck = !!(props.outboundMapping && props.outboundMapping.length);

    const isInboundOverwrite = props.inboundOverwrite;

    return {
      getInboundCheck,
      getOutboundCheck,
      isInboundOverwrite,
    };
  },
});
























































































import {
  defineComponent,
  ref,
  computed,
} from '@vue/composition-api';
import Store from '@/store';

export default defineComponent({
  props: {
    sectionCount: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const panel = 0;
    const dialog = ref(false);
    const form = ref();
    const sectionName = ref('');
    const getSectionNames = computed((): string[] => Store.getters['templateCustomisation/getSectionNames']);

    // Rules for Section Name user input
    const nameRules = [
      (v: string) => !!v || 'Section name is required',
      (v: string) => (v && v.length <= 100)
          || 'Section name must be less than 100 characters',
      (v: string) => (v && !getSectionNames.value.includes(v.trim())) || 'Section name must be unique',
    ];

    /**
       * Closes dialog box
       */
    const closeDialog = () => {
      dialog.value = false;
      form.value.reset();
    };

    /**
       * Add new Section to Vuex, clear v-model & close dialog
       */
    const saveSectionNameBtn = () => {
      const isValidated = form.value.validate();
      if (!isValidated) return;

      Store.dispatch(
        'templateCustomisation/addNewSection',
        {
          name: sectionName.value.trim(),
          order: props.sectionCount,
        },
      );
      closeDialog();
    };

    return {
      form,
      dialog,
      panel,
      nameRules,
      sectionName,
      closeDialog,
      saveSectionNameBtn,
    };
  },
});

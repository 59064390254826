











































































































































































































import {
  defineComponent,
  ref,
  reactive,
  computed,
  PropType,
  watch,
} from '@vue/composition-api';
import _ from 'lodash';
import Store from '@/store';
import {
  IConditionalLogicAlarm, ITemplateSectionField, ITemplateSectionFieldsValidation, IVComboboxItem, LogicItemType, VForm,
} from '@/types';
import InboundOutboundFields from './InboundOutboundFields.vue';
import ConditionalLogicDialog from './ConditionalLogicDialog.vue';
import CalculationLogicDialog from './CalculationLogicDialog.vue';

export default defineComponent({
  components: {
    InboundOutboundFields,
    ConditionalLogicDialog,
    CalculationLogicDialog,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    field: {
      type: Object as PropType<ITemplateSectionField>,
      required: true,
    },
    sectionFieldData: {
      type: Object as PropType<ITemplateSectionFieldsValidation>,
      default: () => {},
      required: true,
    },
    sectionName: {
      type: String,
      required: true,
    },
    isPublished: {
      type: Boolean,
      required: true,
    },
    templateId: {
      type: String,
      required: true,
    },
    subsectionIndex: {
      type: Number,
      required: false,
    },
  },

  setup(props) {
    const form = ref(null as unknown as VForm);
    const tab = null;
    const dialog = ref(false);
    const conditonalLogicDialog = ref();
    const apisCalled = ref(false);
    const calculateLogicDialog = ref();
    const myfield = props.field as ITemplateSectionField;
    const isURL = ref(myfield.type === 'url');

    // Validation for Length of Label
    const rules = {
      lengthRule: (v: string) => (v && v.length <= 100) || 'Label must be less than 100 characters',
    };

    const isLoading = computed(() => Store.getters['conditionalLogic/getLoading']);

    const sectionFieldName = props.sectionName;

    // TODO remove ID key when updating validation
    const fieldData = reactive({
      fieldId: '',
      id: 0,
      inboundMapping: '',
      outboundMapping: '',
      inboundOverwrite: true,
      name: '',
      value: '',
      required: false,
      type: '',
      prefix: '',
      suffix: '',
      readonly: false,
      validation: {
        min: '',
        max: '',
        mask: '',
      },
      size: 'small',
    }) as ITemplateSectionField;

    const fieldSizeOptions: IVComboboxItem[] = [
      { text: 'Small', value: 'small' },
      { text: 'Medium', value: 'medium' },
      { text: 'Large', value: 'large' },
    ];

    /**
  *  @summary Fn to disable condtional logic tab
  *  @description Disable IF: Prev saved alarm with calculations, or newly added calc logic
  *  @author EJ McVey
  */
    const disableConditionalLogic = computed(() => {
      const conditionalAlarms = Store.getters['conditionalLogic/getData'];
      const currentAlarm = conditionalAlarms.filter((alarm: IConditionalLogicAlarm) => alarm.customFieldId === props.field.id);

      // if DB saved calcuation disable
      if (Object.keys(currentAlarm).length === 1 && !currentAlarm[0].newValue && currentAlarm[0].newValueLogic.length) {
        const currentLogic = currentAlarm[0].newValueLogic as LogicItemType[];
        if (currentLogic.length) return true;
      }

      // if newly added calc logic added then disable conditional logic tab
      const getAddedCalc = Store.getters['conditionalLogic/getAddedConditionalLogic'];
      if (getAddedCalc.length === 1 && getAddedCalc[0].calculates.length > 1) return true;

      const getRemovedCalc = Store.getters['conditionalLogic/getDeletedConditionalLogicIds'];

      // if added calculations is empty and removed array has values then enable tab
      if (getAddedCalc.length === 0 && getRemovedCalc.length >= 1) return false;

      return false;
    });

    /**
  *  @summary Fn to disable Calculation logic tab
  *  @description Disable IF: multiple alarms exist (calc can only be one), or if prev saved condit alarm returned
  *  or IF newly added conditional logic alarm
  *  @author EJ McVey
  */
    const disableCalculations = computed(() => {
      const conditionalAlarms = Store.getters['conditionalLogic/getData'];
      const currentAlarm = conditionalAlarms.filter((alarm: IConditionalLogicAlarm) => alarm.customFieldId === props.field.id);

      // If calculation alarm there would only be 1 alarm in array
      if (Object.keys(currentAlarm).length > 1) return true;

      // if DB saved normal conditional logic
      if (currentAlarm[0] && !currentAlarm[0].newValueLogic && currentAlarm[0].newValue) return true;

      const getAddedCalc = Store.getters['conditionalLogic/getAddedConditionalLogic'];
      if (getAddedCalc.length >= 1 && Object.keys(getAddedCalc[0].calculates).length === 0 && getAddedCalc[0].populates) return true;

      return false;
    });

    /**
     * Method triggered when user opens dialog
     * searches field data based off index position
     * returns field data stored and assigns it to main field data obj
     */
    const getFieldData = () => {
      const fieldSavedData = Store.getters['templateCustomisation/getSectionFieldData']({
        sectionName: sectionFieldName,
        fieldIndex: props.index,
        subsectionIndex: props.subsectionIndex,
      }) as ITemplateSectionField;

      Object.keys(fieldSavedData).forEach((key) => {
        fieldData[key] = fieldSavedData[key];
      });

      if (!fieldSavedData.validation) return;
      Object.keys(fieldSavedData.validation).forEach((key) => {
        if (fieldSavedData.validation && fieldData.validation) {
          fieldData.validation[key] = fieldSavedData.validation[key];
        }
      });
    };

    /**
     * Closes dialog box
     */
    const closeDialog = () => {
      Store.commit('conditionalLogic/resetConditionalLogicAlarm');
      dialog.value = false;

      getFieldData();
    };

    /*
    * apisCalled is set when the user has hit 'confirm' and is adding values to the field
    * when the loading is finished and the we closeDialog() and reset value
    */
    watch(isLoading, () => {
      if (!isLoading.value && apisCalled.value) {
        closeDialog();
        apisCalled.value = false;
      }
    });
    watch(dialog, () => {
      if (dialog.value === false && conditonalLogicDialog.value) conditonalLogicDialog.value.clearFields(true);
      if (dialog.value === false && calculateLogicDialog.value) calculateLogicDialog.value.getCalculateLogic();
    });

    /**
     * Returns title card text depending on published status
     */
    const getCardTitleText = computed(() => {
      let title = `Configure '${fieldData.name}' ${props.field ? props.field.type : ''} Component`;
      if (props.isPublished) title = title.replace('Configure', 'View');
      return title;
    });

    /**
     * Return cancel button text depending on published status
     */
    const getCloseBtnText = computed(() => (props.isPublished ? 'Close' : 'Cancel'));

    /**
     * Validation rule for min & max number input
     */
    const numberRule = (v: string | number) => {
      if (!v) return true;
      if (_.isNaN(v)) return 'Has to be a valid number';
      return true;
    };

    /**
     * Save updated data into vuex
     */
    const saveValues = () => {
      // Dont allow user to confirm if Label validation fails
      const isValidated = form.value.validate();
      if (!isValidated) return;

      // url field is a new field in BE, however we mask it as a text field here
      // rather than adding a new field component for the end-user.
      if (isURL.value && fieldData.type === 'text') {
        fieldData.type = 'url';
      } else if (!isURL.value && fieldData.type === 'url') {
        fieldData.type = 'text';
      }

      const updateFieldDetails = {
        sectionName: sectionFieldName,
        index: props.index,
        subsectionIndex: props.subsectionIndex,
        fieldData,
      };

      apisCalled.value = true;
      Store.dispatch(
        'templateCustomisation/updateIndividualField',
        updateFieldDetails,
      );

      /*
      * if there is no conditional logic to delete or add we can straight away close the dialog
      * if there is the dialog stays open and we fire delete alarms
      */
      if (Store.getters['conditionalLogic/getDeletedConditionalLogicIds'].length === 0 && Store.getters['conditionalLogic/getAddedConditionalLogic'].length === 0) closeDialog();
      else {
        Store.dispatch('conditionalLogic/deleteAlarms', {
          workOrderTemplateId: props.templateId,
          calledFromStatusLogic: false,
        });
      }
    };

    return {
      tab,
      isURL,
      form,
      dialog,
      closeDialog,
      saveValues,
      fieldData,
      getFieldData,
      sectionFieldName,
      numberRule,
      rules,
      getCardTitleText,
      getCloseBtnText,
      conditonalLogicDialog,
      calculateLogicDialog,
      isLoading,
      disableCalculations,
      disableConditionalLogic,
      fieldSizeOptions,
    };
  },
});









































































































































































import {
  defineComponent,
  ref,
  reactive,
  computed,
  PropType,
  watch,
} from '@vue/composition-api';
import Store from '@/store';
import {
  ITemplateSectionField, ISelectFieldOption, VForm, ITemplateSectionFieldsValidation, IVComboboxItem,
} from '@/types';
import InnerConfigTableComponent from './InnerConfigTableComponent.vue';
import ConditionalLogicDialog from './ConditionalLogicDialog.vue';
import InboundOutboundFields from './InboundOutboundFields.vue';

export default defineComponent({
  components: {
    InnerConfigTableComponent,
    InboundOutboundFields,
    ConditionalLogicDialog,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    field: {
      type: Object as PropType<ITemplateSectionField>,
      default: () => {},
      required: true,
    },
    sectionFieldData: {
      type: Object as PropType<ITemplateSectionFieldsValidation>,
      default: () => {},
      required: true,
    },
    sectionName: {
      type: String,
      required: true,
    },
    isPublished: {
      type: Boolean,
      required: true,
    },
    templateId: {
      type: String,
      required: true,
    },
    subsectionIndex: {
      type: Number,
      required: false,
    },
  },

  setup(props) {
    const form = ref(null as unknown as VForm);
    const tab = null;

    const dialog = ref(false);
    const conditonalLogicDialog = ref();
    const apisCalled = ref(false);

    const sectionFieldName = props.sectionName;

    const isLoading = computed(() => Store.getters['conditionalLogic/getLoading']);

    // TODO remove ID key when updating validation
    const fieldData = reactive({
      fieldId: '',
      id: 0,
      inboundMapping: '',
      outboundMapping: '',
      inboundOverwrite: true,
      name: '',
      value: '',
      required: false,
      type: '',
      readonly: false,
      structure: [] as ISelectFieldOption[],
      size: 'small',
    }) as ITemplateSectionField;

    const tableHeaders = [
      { text: 'Code', value: 'code', width: '40%' },
      { text: 'Description', value: 'description', width: '40%' },
      {
        text: '', value: 'actions', sortable: false, align: 'right',
      },
    ];

    const fieldSizeOptions: IVComboboxItem[] = [
      { text: 'Small', value: 'small' },
      { text: 'Medium', value: 'medium' },
      { text: 'Large', value: 'large' },
    ];
    /**
     * Method triggered when user opens dialog
     * searches field data based off index position
     * returns field data stored and assigns it to main field data obj
     */
    const getFieldData = () => {
      const fieldSavedData = Store.getters['templateCustomisation/getSectionFieldData']({
        sectionName: sectionFieldName,
        fieldIndex: props.index,
        subsectionIndex: props.subsectionIndex,
      }) as ITemplateSectionField;

      Object.keys(fieldSavedData).forEach((key) => {
        fieldData[key] = fieldSavedData[key];
      });
    };

    /**
     * Closes dialog box
     */
    const closeDialog = () => {
      Store.commit('conditionalLogic/resetConditionalLogicAlarm');
      dialog.value = false;
      getFieldData();
    };

    /*
    * apisCalled is set when the user has hit 'confirm' and is adding values to the field
    * when the loading is finished and the we closeDialog() and reset value
    */
    watch(isLoading, () => {
      if (!isLoading.value && apisCalled.value) {
        closeDialog();
        apisCalled.value = false;
      }
    });
    watch(dialog, () => {
      if (dialog.value === false && conditonalLogicDialog.value) conditonalLogicDialog.value.clearFields(true);
    });

    // Validation for Length of Label
    const rules = {
      lengthRule: (v: string) => (v && v.length <= 100) || 'Label must be less than 100 characters',
    };

    /**
     * Returns title card text depending on published status
     */
    const getCardTitleText = computed(() => {
      let title = `Configure '${fieldData.name}' ${props.field ? props.field.type : ''} Component`;
      if (props.isPublished) title = title.replace('Configure', 'View');
      return title;
    });

    /**
     * Return cancel button text depending on published status
     */
    const getCloseBtnText = computed(() => (props.isPublished ? 'Close' : 'Cancel'));

    /**
     * Saves new/edited select option values from Select Data Table
     */
    const saveNewOptionValues = (updatedOptions: ISelectFieldOption[]) => {
      if (fieldData) fieldData.structure = updatedOptions;
    };

    /**
     * Disables saving if no options entered
     */
    const optionsSelected = computed(
      () => fieldData.structure && fieldData.structure.length,
    );

    /**
     * Save updated data into vuex
     */
    const saveValues = () => {
      // Dont allow user to confirm if form validation fails
      if (!form.value.validate()) return;

      const fieldDetails = {
        sectionName: sectionFieldName,
        index: props.index,
        subsectionIndex: props.subsectionIndex,
        fieldData,
      };

      apisCalled.value = true;
      Store.dispatch(
        'templateCustomisation/updateIndividualField',
        fieldDetails,
      );

      /*
      * if there is no conditional logic to delete or add we can straight away close the dialog
      * if there is the dialog stays open and we fire delete alarms
      */
      if (Store.getters['conditionalLogic/getDeletedConditionalLogicIds'].length === 0 && Store.getters['conditionalLogic/getAddedConditionalLogic'].length === 0) closeDialog();
      else {
        Store.dispatch('conditionalLogic/deleteAlarms', {
          workOrderTemplateId: props.templateId,
          calledFromStatusLogic: false,
        });
      }
    };

    return {
      form,
      tab,
      rules,
      dialog,
      closeDialog,
      fieldData,
      tableHeaders,
      saveValues,
      getFieldData,
      sectionFieldName,
      saveNewOptionValues,
      getCardTitleText,
      getCloseBtnText,
      optionsSelected,
      conditonalLogicDialog,
      isLoading,
      fieldSizeOptions,
    };
  },
});

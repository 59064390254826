


























































import { defineComponent, ref } from '@vue/composition-api';
import Store from '@/store';

export default defineComponent({
  props: {
    sectionName: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const dialog = ref(false);

    /**
       * Closes dialog box
       */
    const closeDialog = () => {
      dialog.value = false;
    };

    /**
       * Main fn to save template changes
       */
    const deleteSection = async () => {
      Store.dispatch('templateCustomisation/deleteSection', props.sectionName);
      closeDialog();
      // show success or failure notification here
    };

    return {
      dialog,
      closeDialog,
      deleteSection,
    };
  },
});

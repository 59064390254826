


















































































































































































































































































































import {
  computed, defineComponent, reactive, ref, PropType, watch,
} from '@vue/composition-api';
import {
  ITemplateFieldConditionalLogic, IVComboboxItem, ISelectFieldOption, IWorkOrderSectionFile, ITemplateSectionField, IConditionalLogicAlarmConditions, IConditionalLogicAlarm, IConditionalLogicAlarmDelete, DateLogicType, ITemplateSectionFieldsValidation, ITemplateStatus,
} from '@/types';
import router from '@/router';
import Store from '@/store';
import DateTimePicker from '../DateTimePicker.vue';
import ConditionalLogicSelection from './ConditionalLogicSelection.vue';

export default defineComponent({
  components: {
    ConditionalLogicSelection,
    DateTimePicker,
  },
  props: {
    currentSectionName: {
      type: String,
      required: true,
    },
    sectionFieldData: {
      type: Object as PropType<ITemplateSectionFieldsValidation>,
      default: () => {},
      required: true,
    },
    currentField: {
      type: Object as PropType<ITemplateSectionField>,
      default: () => {},
      required: true,
    },
    isPublished: {
      type: Boolean,
      required: true,
    },
    selectFieldData: {
      type: Array as PropType<ISelectFieldOption[]>,
      default: () => [],
    },
    statusLogic: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const generateId = () => Math.floor(100000 + Math.random() + Math.random() * 50000000);
    const addedLogic = ref([] as IVComboboxItem[]);
    const dateCalculateLogicAdded = ref(false);
    const checkboxValues = [{
      text: 'Checked',
      value: true,
    },
    {
      text: 'Unchecked',
      value: false,
    }];
    const popValidation = computed(() => (props.currentField.id && props.sectionFieldData[props.currentField.id] ? props.sectionFieldData[props.currentField.id].validation : {}));
    const errorMessages = ref('');
    const dateTimeValue = ref();
    const dateValueComp = ref();
    const conditialLogicSection = ref();
    const filePopulatesInput = ref(null);
    const tab = ref(null);
    const { templateId } = router.app.$route.params;

    watch(addedLogic, () => {
      if (!Object.keys(addedLogic.value).length) dateCalculateLogicAdded.value = false;
    });

    const operatorsAvailable = [{
      text: 'Add x days',
      value: 'add_x_days',
    },
    {
      text: 'Minus x days',
      value: 'minus_x_days',
    }];

    const dayTypes = [{
      text: 'Calendar Days',
      value: 'calendar_days',
    },
    {
      text: 'Work Days',
      value: 'work_days',
    }];

    const conditionalLogicHeaders = [
      {
        text: 'Condition',
        align: 'start',
        value: 'text',
      },
      {
        text: 'Populates',
        value: 'value',
      },
      { text: 'Actions', value: 'actions', sortable: false },
    ];

    const isLoading = computed(() => Store.getters['conditionalLogic/getLoading']);

    /**
     * Return only date fields for date calculation
     */
    const dateFields = computed((): ITemplateSectionField[] => {
      const fields = Store.getters['templateCustomisation/getAllSectionsFields'];

      return fields.filter((field: ITemplateSectionField) => field.type === 'date').map((dateField: ITemplateSectionField) => (
        {
          text: dateField.name,
          value: dateField.id,
        }));
    });

    const fileRules = {
      noSpecialCharacters: (file: IWorkOrderSectionFile) => {
        if (!file) return true;
        const regex = new RegExp(/^[a-zA-Z0-9\s_.-]+$/);
        return (
          regex.test(file.name)
          || 'Only alpha numeric characters, hyphens, underscores and spaces allowed'
        );
      },
      lengthRule: (file: IWorkOrderSectionFile) => {
        if (!file) return true;
        return (
          (file.name.length >= 3 && file.name.length <= 100)
          || 'File name must be between 3 and 100 characters'
        );
      },
      onlyAllowedFormats: (file: IWorkOrderSectionFile) => {
        if (!file) return true;
        const allowedFileTypes = [
          'jpeg',
          'png',
          'doc',
          'docx',
          'csv',
          'xls',
          'xlsx',
          'pdf',
          'txt',
          'text',
          'rtf',
        ];
        const isAllowed = allowedFileTypes.some((fileType) => file.type.includes(fileType));
        return isAllowed || 'Invalid file type';
      },
      maxSize: (file: IWorkOrderSectionFile) => {
        if (!file) return true;
        return file.size <= 10000000 || 'Max file size is 10mb';
      },
    };

    /**
     * Set the input field error message prop
     */
    const setErrorMessages = (value: string) => {
      errorMessages.value = value;
    };

    /* NOTE: one element is pre-added as the first element in the UI,
     when looping over conditionalFields above the first element is skipped */
    const conditionalLogic = reactive({
      populates: '',
      calculates: {},
      populatesFile: {
        lastModified: 0,
        lastModifiedDate: '',
        name: '',
        size: 0,
        type: '',
        id: 0,
        originalFileName: '',
        fileId: 0,
        originalName: '',
      },
      populatesCalculateDate: {
        field: {
          text: '',
          value: '',
        },
        operator: {
          text: '',
          value: '',
        },
        days: '',
        daysType: {
          text: '',
          value: '',
        },
      },
      populatesSelectField: {
        code: '',
        description: '',
      },
      statusPopulatesValue: {
        text: '',
        value: '',
      },
      conditionalFields: [{
        id: generateId(),
        field: {
          inboundMapping: '',
          name: '',
          type: '',
        },
        condition: {
          text: '',
          value: '',
          operatorAnd: false,
        },
        value: '',
        selectCode: '',
      }] as ITemplateFieldConditionalLogic[],
    });

    /* Validation for Length of Value */
    const rules = {
      lengthRule: (value: string, id: number): boolean | string => {
        const { validation } = props.sectionFieldData[id];
        if (!value) return true;
        return (value.length <= (validation.max || 100) && value.length >= (validation.min || 1)) || `Value must be less than ${validation.max || 100} and greather then ${validation.min || 1} in length.`;
      },
      numberLengthRule: (value: string): boolean | string => {
        if (!value) return true;
        return (value.length <= 100 && value.length >= 1) || 'Value must be less than 100 and greater then 1 in length.';
      },
      numberValueRule: (value: string, id: number): boolean | string => {
        const { validation } = props.sectionFieldData[id];
        if (!value) return true;
        if (value > validation.max && value < validation.min) return `Max Value is ${validation.max} and min value is${validation.min}.`;
        if (value > validation.max) return `Max value is ${validation.max}.`;
        if (value < validation.min) return `Min Value is ${validation.min}.`;
        return true;
      },
      populatesLengthRule: (value: string): boolean | string => {
        if (!value) return true;
        return (value.length <= (popValidation.value.max || 100) && value.length >= (popValidation.value.min || 1)) || `Value must be less than ${popValidation.value.max || 100} and greather then ${popValidation.value.min || 1} in length.`;
      },
      populatesNumberLengthRule: (value: string): boolean | string => {
        if (!value) return true;
        return (value.length <= 100 && value.length >= 1) || 'Value must be less than 100 and greater then 1 in length.';
      },
      populatesNumberValueRule: (value: string): boolean | string => {
        if (!value) return true;
        if (value > popValidation.value.max && value < popValidation.value.min) return `Max Value is ${popValidation.value.max} and min value is${popValidation.value.min}.`;
        if (value > popValidation.value.max) return `Max value is ${popValidation.value.max}.`;
        if (value < popValidation.value.min) return `Min Value is ${popValidation.value.min}.`;
        return true;
      },
    };
      /* checks all fields and populates have a value */
    const validConditionalLogic = computed(() :boolean => {
      /*  check for all populates field execpt check box (exception for status logic checked line below separately) */
      if (!props.statusLogic && !conditionalLogic.populatesSelectField.code && !conditionalLogic.populates && (!conditionalLogic.populatesFile || !conditionalLogic.populatesFile.name) && props.currentField.type !== 'checkbox') return false;
      /* if status logic check the user has selected a populate status */
      if (props.statusLogic && !conditionalLogic.statusPopulatesValue.text) return false;
      /*  check for populates field for check box */
      if (typeof (conditionalLogic.populates) !== 'boolean' && props.currentField.type === 'checkbox') return false;
      /* check every field has a value and check length rule if applicable */
      const validation = conditionalLogic.conditionalFields.every(
        (item) => item.field && item.condition.text && (item.value || item.field.type === 'file' || (item.field.type === 'date' && item.condition.value === 'not_empty'))
        && (typeof rules.lengthRule(item.value, item.field.id) !== 'string' || item.field.type === 'checkbox' || item.field.type === 'number')
        && ((typeof rules.numberValueRule(item.value, item.field.id) !== 'string' && typeof rules.numberLengthRule(item.value) !== 'string') || item.field.type !== 'number'),
      );
      const required = rules.populatesLengthRule(conditionalLogic.populates);
      const numberLength = rules.populatesNumberLengthRule(conditionalLogic.populates);
      const numberValue = rules.populatesNumberValueRule(conditionalLogic.populates);
      if (props.statusLogic && validation && conditionalLogic.statusPopulatesValue.text) return true;
      return (typeof required === 'boolean' || props.currentField.type === 'checkbox' || props.currentField.type === 'number') && ((typeof numberLength === 'boolean' && typeof numberValue === 'boolean') || props.currentField.type !== 'number') && validation;
    });

    /* checks all fields and populates have a value */
    const validConditionalLogicCalculateDate = computed(() :boolean => {
      if (!conditionalLogic.populatesCalculateDate.operator.text || !conditionalLogic.populatesCalculateDate.days || !conditionalLogic.populatesCalculateDate.daysType.text) return false;

      /* check every field has a value and check length rule if applicable */
      const validation = conditionalLogic.conditionalFields.every(
        (item) => item.field && item.condition.text && (item.value || item.field.type === 'file' || (item.field.type === 'date' && item.condition.value === 'not_empty'))
        && (typeof rules.lengthRule(item.value, item.field.id) !== 'string' || item.field.type === 'checkbox' || item.field.type === 'number')
        && ((typeof rules.numberValueRule(item.value, item.field.id) !== 'string' && typeof rules.numberLengthRule(item.value) !== 'string') || item.field.type !== 'number'),
      );

      const required = rules.populatesLengthRule(conditionalLogic.populates);
      const numberLength = rules.populatesNumberLengthRule(conditionalLogic.populates);
      const numberValue = rules.populatesNumberValueRule(conditionalLogic.populates);
      /* check validation for status logic */

      return (typeof required === 'boolean' || props.currentField.type === 'checkbox' || props.currentField.type === 'number') && ((typeof numberLength === 'boolean' && typeof numberValue === 'boolean') || props.currentField.type !== 'number') && validation;
    });

    /**
     * Parse date string to DD/MM/YYYY format
     */
    const formatDate = (date: string) => {
      if (!date) return '';
      return new Date(date).toLocaleString().split(',')[0];
    };

    /**
     * Parse date string to DD/MM/YYYY format
     */
    const formatDateTime = (date: string) => {
      if (!date) return '';
      const formattedDate = new Date(date).toLocaleString().split(',');
      return formattedDate[0] + formattedDate[1];
    };

    /**
     *  @summary computed propertie calculates the display value for the conditional logic the user enters
     *  @auuthor Rory Haford
     */
    const conditionalLogicOutcome = computed(() :string => {
      let conLogic = '';
      conditionalLogic.conditionalFields.forEach((value) => {
        if (conLogic === '') conLogic = 'IF ';
        else conLogic += `\n${value.operatorAnd ? 'AND' : 'OR'} '`;

        let dateValue = value.value;
        let conditionValue = value.condition.text.toString();
        if (value.condition.text !== 'Doesn\'t equal' && value.condition.text) conditionValue = `is ${value.condition.text.toString()}`;

        switch (value.field.type) {
          case 'file':
            conLogic += `'${value.field.name}' has '${conditionValue}'`;
            break;
          case 'date':
            dateValue = formatDate(dateValue);
            conLogic += `'${value.field.name}' ${conditionValue} '${dateValue}'`;
            break;
          case 'datetime':
            dateValue = formatDateTime(dateValue);
            conLogic += `'${value.field.name}' ${conditionValue} '${dateValue}'`;
            break;
          case 'select':
            conLogic += `'${value.field.name}' ${conditionValue} '${value.value}'`;
            break;
          default:
            conLogic += `'${value.field.name}' ${conditionValue} '${value.value}'`;
        }
      });

      let { populates } = conditionalLogic;
      /*
      * depending on the type of object the populates is different
      * first if statement checks if its a file returning the file name
      * second if statement checks if its a check box
      * third if statement makes sure it isn't in the calculate section of the datefield
      */
      if (conditionalLogic.populatesFile.name) populates = conditionalLogic.populatesFile.name;
      else if (props.currentField.type === 'checkbox' && typeof (populates) === 'boolean') conLogic += `\nPOPULATE '${props.currentSectionName}' with '${populates === true ? 'Checked' : 'Unchecked'}'`;
      else if ((populates || conditionalLogic.populatesSelectField.code) && (tab.value === 0 || (props.currentField.type !== 'datefield' && props.currentField.type !== 'date'))) {
        /* format date field or select field */
        if (props.currentField.type === 'date') populates = formatDate(populates);
        else if (props.currentField.type === 'datetime') populates = formatDateTime(populates);
        else if (props.currentField.type === 'select') populates = conditionalLogic.populatesSelectField.description;
        else conLogic += `\nPOPULATE '${props.currentField.name}' with '${populates}'`;
      } else if (tab.value === 1) {
        conLogic += `\nCALCULATE '${conditionalLogic.populatesCalculateDate.field.text}' with '${conditionalLogic.populatesCalculateDate.operator.text.replace('x days', `${conditionalLogic.populatesCalculateDate.days ? conditionalLogic.populatesCalculateDate.days : ''} ${conditionalLogic.populatesCalculateDate.daysType.text}`)}'`;
      } else if (props.statusLogic && conditionalLogic.statusPopulatesValue.text) conLogic += `\nCHANGE status to ${conditionalLogic.statusPopulatesValue.text}'`;

      return conLogic;
    });

    const woTemplateStatuses = computed(() => {
      const status = Store.getters['templateStatus/getData'];
      return status.map((item: ITemplateStatus) => ({
        text: item.name,
        value: item.id,
      }));
    });

    /* adds another condition to the conditional fields */
    const addAnotherCondition = () => {
      conditionalLogic.conditionalFields.push({
        id: generateId(),
        field: {
          inboundMapping: '',
          name: '',
          type: '',
          id: -1,
          tempId: '-1',
        },
        condition: {
          text: '',
          value: '',
        },
        operatorAnd: false,
        value: '',
        selectCode: '',
      });
    };

    /* Used to delete conditional logic that has been added */
    const onDeleteConditionalLogic = (item: IVComboboxItem) => {
      addedLogic.value = addedLogic.value.filter((conditionLogic) => conditionLogic.id !== item.id);
      Store.dispatch('conditionalLogic/removeConditionalLogicAlarm', item);
    };

    /**
     *  @summary Conditional logic items gets all the added conditional logic and formats it for display
     *  @description This computed property loops around all Conditional logic Saved/Unsaved it checks gets the condtional logic for the
     * specific field filters out all deleted items and formats the dats to display.
     *  @author Rory Harford
     */
    const conditionalLogicItems = computed(() => {
      let conditionalAlarms = props.statusLogic ? Store.getters['conditionalLogic/getStatusData'] : Store.getters['conditionalLogic/getData'];
      const deletedAlarms = Store.getters['conditionalLogic/getDeletedConditionalLogicIds'];

      /* filter out other the fields alarms and deleted alarms */
      conditionalAlarms = conditionalAlarms.filter((alarm: IConditionalLogicAlarm) => alarm.customFieldId === props.currentField.id && deletedAlarms.findIndex((item: IConditionalLogicAlarmDelete) => item.eventId === alarm.id) === -1);
      conditionalAlarms.forEach((element:IConditionalLogicAlarm) => {
        /*
        * ensure the element has conditons because that means it is a 'change_field_value' alarm i.e conditonal logic
        * && check the element isn't already present in added logic
        */
        let calculateAnswer = '';
        const DateFieldToCalculate = ref('');
        if (element.newValueLogic && Object.keys(element.newValueLogic)) {
          dateCalculateLogicAdded.value = true;

          const logic = element.newValueLogic as DateLogicType;
          if (logic.dateFieldName) DateFieldToCalculate.value = logic.dateFieldName;
          calculateAnswer = `${logic.condition === 'add_x_days' ? 'Add' : 'Minus'} ${logic.numberOfDays} ${logic.dayType === 'calendar_days' ? 'Calendar' : 'Work'} Days to ${logic.dateFieldName}`;
        }

        if (element.conditions && addedLogic.value.findIndex((item) => item.id === element.conditionId) === -1) {
          let conditionValue = '';
          let conLogic = '';
          let fieldsSelectOptions;
          let selectedField;
          let populates = dateCalculateLogicAdded.value ? calculateAnswer : element.newValue;
          if (!populates) populates = element.statusId;
          if (props.currentField.fieldType === 'date' && !dateCalculateLogicAdded.value) populates = formatDate(populates);
          else if (props.currentField.fieldType === 'datetime' && !dateCalculateLogicAdded.value) populates = formatDateTime(populates);
          element.conditions.forEach((value) => {
            conditionValue = value.criteriaText;
            if (conLogic === '') conLogic = '-> IF ';
            else conLogic += `\n-> ${value.operator}  `;
            let dateValue = value.value;

            switch (value.fieldType) {
              case 'file':
                conLogic += `'${value.fieldName}' ${conditionValue === 'Equal to' ? ' has  file present' : 'file isnt present'}`;
                break;
              case 'date':
                dateValue = formatDate(dateValue);
                conLogic += `'${DateFieldToCalculate.value}' ${conditionValue} '${dateValue}'`;
                break;
              case 'datetime':
                dateValue = formatDateTime(dateValue);
                conLogic += `'${value.fieldName}' ${conditionValue} '${dateValue}'`;
                break;
              case 'select':
                /* select field returns the code we use this code to find the matching description for the combobox */
                fieldsSelectOptions = Store.getters['templateCustomisation/getSectionSelectFieldData']({
                  fieldId: value.customFieldId,
                });
                selectedField = fieldsSelectOptions.filter((options: ISelectFieldOption) => options.code === value.value);
                conLogic += `'${value.fieldName}' ${conditionValue} '${selectedField[0].description}'`;
                break;
              default:
                conLogic += `'${value.fieldName}' ${conditionValue} '${value.value}'`;
            }
          });

          if (props.currentField.type === 'checkbox' && populates) populates = (populates.toString() === '1' || populates.toString() === 'true') ? 'Checked' : 'Unchecked';
          /* only add if it has a conditionId and the field hasn't already been added */
          if (element.conditionId) {
            if (props.statusLogic) populates = woTemplateStatuses.value.find((status: IVComboboxItem) => status.value === populates).text;
            else if (props.currentField.type === 'date' && !dateCalculateLogicAdded.value) populates = formatDate(populates);
            else if (props.currentField.type === 'datetime' && !dateCalculateLogicAdded.value) populates = formatDateTime(populates);
            else if (props.currentField.type === 'select' && props.selectFieldData) populates = props.selectFieldData.find((item: ISelectFieldOption) => item.code === populates)?.description || '';

            addedLogic.value.push({
              id: element.conditionId,
              eventId: element.id,
              text: conLogic,
              value: populates,
            });
          }
        }
      });
      return addedLogic.value;
    });

    /**
    * function is used to clear the values, used when value added or canceled
    * when canceled it is called through the parent compoenent when the dialog is closed
    * @param {boolean} dialogIsClosing this value is set to true when called from the parent comp it is to indicate the
    * dialog is closing to clear all conditional logic data
    */
    const clearFields = (dialogIsClosing = false) => {
      if (dialogIsClosing) addedLogic.value = [];
      conditionalLogic.conditionalFields = [{
        id: generateId(),
        field: {
          inboundMapping: '',
          name: '',
          type: '',
          id: -1,
          tempId: '-1',
        },
        condition: {
          text: '',
          value: '',
        },
        operatorAnd: false,
        value: '',
        selectCode: '',
      }];
      conditionalLogic.populates = '';
      conditionalLogic.populatesFile = {
        lastModified: 0,
        lastModifiedDate: '',
        name: '',
        size: 0,
        type: '',
        id: 0,
        originalFileName: '',
        fileId: 0,
        originalName: '',
      };
      conditionalLogic.populatesCalculateDate = {
        field: {
          text: '',
          value: '',
        },
        operator: {
          text: '',
          value: '',
        },
        days: '',
        daysType: {
          text: '',
          value: '',
        },
      };
      conditionalLogic.populatesSelectField = {
        code: '',
        description: '',
      };
      conditionalLogic.statusPopulatesValue = {
        value: '',
        text: '',
      };

      conditialLogicSection.value?.clearFields();
      if (dateTimeValue.value) dateTimeValue.value.onClearField();
      if (dateValueComp.value) dateValueComp.value.onClearField();
      filePopulatesInput.value = null;
    };

    /**
     *  @summary function addField prepares the values to be sent to the back end api and creates the display for the added conditonal logic
     *  @author Rory Harford
    */
    const addField = (calculateDate = false) => {
      let { populates, calculates } = conditionalLogic;

      // if no populates value need to check populatesFile
      /* will be removing file upload */
      if (!populates && props.currentField.type !== 'checkbox' && props.currentField.type !== 'select') {
        populates = conditionalLogic.populatesFile.name;
      }

      const conditionalAlarmId = generateId();

      let conLogic = '';
      const conditionalLogicAlarm = {
        tempId: conditionalAlarmId,
        name: props.currentSectionName,
        workOrderId: templateId,
        conditions: [] as IConditionalLogicAlarmConditions[],
        customFieldId: 0,
        populates: '',
        calculates: {},
      };

      conditionalLogic.conditionalFields.forEach((value) => {
        conditionalLogicAlarm.conditions.push({
          operator: !value.operatorAnd ? 'OR' : 'AND',
          customFieldId: value.field.id,
          criteria: value.condition.value?.toString() || '',
          criteriaText: '',
          fieldName: '',
          fieldType: '',
          value: value.field.type === 'select' ? value.selectCode : value.value,
        });

        if (props.currentField.type === 'date' && conditionalLogic.populatesCalculateDate.days) {
          calculates = {
            dateFieldId: conditionalLogic.populatesCalculateDate.field.value,
            condition: conditionalLogic.populatesCalculateDate.operator.value,
            numberOfDays: conditionalLogic.populatesCalculateDate.days,
            dayType: conditionalLogic.populatesCalculateDate.daysType.value,
          };

          conditionalLogicAlarm.conditions.push({
            operator: 'AND',
            // ! tells typescript that even tho you think its null its definitely there
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            customFieldId: parseInt(conditionalLogic.populatesCalculateDate.field.value, 10),
            criteria: 'not_empty',
            criteriaText: '',
            fieldName: '',
            fieldType: '',
            value: '',
          });
        }

        let conditionValue = value.condition.text.toString();
        if (value.condition.text !== 'Doesn\'t equal' && value.condition.text) conditionValue = `is ${value.condition.text.toString()}`;
        if (conLogic === '') conLogic = '-> IF ';
        else conLogic += `\n-> ${value.operatorAnd ? 'AND' : 'OR'}  '`;
        let dateValue = value.value;
        switch (value.field.type) {
          case 'file':
            conLogic += `'${value.field.name}' has '${value.condition.text}'`;
            break;
          case 'date':
            dateValue = formatDate(dateValue);
            conLogic += `'${value.field.name}' ${conditionValue} '${dateValue}'`;
            break;
          case 'datetime':
            dateValue = formatDateTime(dateValue);
            conLogic += `'${value.field.name}' ${conditionValue} '${dateValue}'`;
            break;
          default:
            conLogic += `'${value.field.name}' ${conditionValue} '${value.value}'`;
        }
      });

      conditionalLogicAlarm.customFieldId = props.currentField.id ? props.currentField.id : 0;
      conditionalLogicAlarm.populates = populates;
      conditionalLogicAlarm.calculates = calculates;
      if (props.statusLogic) conditionalLogicAlarm.populates = conditionalLogic.statusPopulatesValue.value;
      if (props.currentField.type === 'select') conditionalLogicAlarm.populates = conditionalLogic.populatesSelectField.code;
      Store.dispatch('conditionalLogic/addConditionalLogicAlarm', conditionalLogicAlarm);

      if ((props.currentField.type === 'date' || props.currentField.type === 'datetime') && calculateDate === true) populates = conditionalLogic.populatesCalculateDate.operator.text.replace('x days', `${conditionalLogic.populatesCalculateDate.days} ${conditionalLogic.populatesCalculateDate.daysType.text} to ${conditionalLogic.populatesCalculateDate.field.text}`);
      else if (props.currentField.type === 'date') populates = formatDate(populates);
      else if (props.currentField.type === 'datetime') populates = formatDateTime(populates);
      else if (props.statusLogic) populates = conditionalLogic.statusPopulatesValue.text.toString();
      else if (props.currentField.type === 'select') populates = conditionalLogic.populatesSelectField.description;
      else if (props.currentField.type === 'checkbox' && populates.toString()) populates = (populates.toString() === '1' || populates.toString() === 'true') ? 'Checked' : 'Unchecked';

      addedLogic.value.push({
        id: conditionalAlarmId,
        text: conLogic,
        value: populates,
      });

      if (populates.includes('Add') || populates.includes('Minus')) dateCalculateLogicAdded.value = true;
      clearFields();
    };

    /**
     *  @summary updates populatesFile if user uploaded a file
     *  @author Rory Harford
     */
    const updateFieldValue = (file: IWorkOrderSectionFile) => {
      conditionalLogic.populatesFile = file;
    };

    /**
     * Validation function for number fields, prevents the user from entering any characters
     * other than numbers and one decimal point '.'
     */
    const isNumber = (event: KeyboardEvent, value: string) => {
      if (!/\d/.test(event.key) && (event.key !== '.' || /\./.test(value))) {
        return event.preventDefault();
      }
      return true;
    };

    /**
     * Validation function for number fields, prevents the user from entering any characters
     */
    const isWholeNumber = (event: KeyboardEvent) => {
      if (!/\d/.test(event.key)) return event.preventDefault();
      return true;
    };

    return {
      validConditionalLogic,
      validConditionalLogicCalculateDate,
      conditionalLogic,
      generateId,
      addField,
      addAnotherCondition,
      onDeleteConditionalLogic,
      conditionalLogicHeaders,
      addedLogic,
      rules,
      fileRules,
      isNumber,
      isWholeNumber,
      checkboxValues,
      setErrorMessages,
      errorMessages,
      formatDate,
      dateTimeValue,
      dateValueComp,
      conditialLogicSection,
      updateFieldValue,
      filePopulatesInput,
      conditionalLogicOutcome,
      tab,
      operatorsAvailable,
      dayTypes,
      clearFields,
      conditionalLogicItems,
      isLoading,
      dateFields,
      dateCalculateLogicAdded,
      popValidation,
      woTemplateStatuses,
    };
  },
});

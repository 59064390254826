











































































































import {
  ref, computed, defineComponent,
} from '@vue/composition-api';
import Store from '@/store';
import { IAuditTrailStoreStateFilter } from '@/types';

export default defineComponent({
  setup() {
    const dialog = ref(false);

    const headers = [
      {
        text: 'Field',
        align: 'start',
        value: 'field',
      },
      { text: 'Action', value: 'action' },
      { text: 'User', value: 'user' },
      { text: 'Date', value: 'date' },
    ];

    const auditTrailItems = [
      {
        field: 'LOS Date',
        action: 'Added',
        user: 'Peter Hernandez',
        date: '2021-10-18T14:00:00Z',
      },
      {
        field: 'Function',
        action: 'Added',
        user: 'Julia Smith',
        date: '2021-10-18T14:30:00Z',
      },
      {
        field: 'Description',
        action: 'Updated validation',
        user: 'Julia Smith',
        date: '2021-10-18T14:30:00Z',
      },
    ];

    const templateName = computed(() => Store.getters['templateCustomisation/getData'].identifier);

    const filterObject = computed(
      () => Store.getters[
        'auditTrail/getFilter'
      ] as IAuditTrailStoreStateFilter,
    );

    /**
     * @summary Closes audit trail dialog
     * @author Ewa Murjas
     */
    const closeDialog = () => {
      dialog.value = false;
    };

    return {
      dialog,
      closeDialog,
      headers,
      auditTrailItems,
      filterObject,
      templateName,
    };
  },
});

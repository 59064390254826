
















































import {
  defineComponent, ref, computed, PropType,
} from '@vue/composition-api';
import router from '@/router';
import Store from '@/store';
import { ITemplateSectionFieldsValidation } from '@/types';
import SaveTemplateChangesDialog from './SaveTemplateChangesDialog.vue';
import AuditTrailDialog from './AuditTrailDialog.vue';
import ConditionalLogicStatus from '../ConditionalLogicStatus.vue';

export default defineComponent({
  components: {
    SaveTemplateChangesDialog,
    AuditTrailDialog,
    ConditionalLogicStatus,
  },
  props: {
    isPublished: {
      type: Boolean,
      required: true,
    },
    sectionFieldData: {
      type: Object as PropType<ITemplateSectionFieldsValidation>,
      default: () => {},
      required: true,
    },
  },
  setup() {
    const loader = ref(null);
    const loading = ref(false);
    const { templateId } = router.app.$route.params;
    const isLoading = computed(() => Store.getters['workOrderTemplate/getLoading']);

    /**
     *  @summary opens the template creation wizard where user can edit/view details on the template
     *  @description
     *      Depenping if the template is published/unpublished clicking the config will lead to different screens.
     *      IF published the 'config' button will lead to the review screen where user can see all details but make no edits.
     *      IF unpublished the user will be presented to the template create wixard screen on screen '2', this is the screen
     *      after they have added the template name. User will be able to edit all details on the template.
     *  @author Rory Harford
     */
    const onConfigClicked = () => {
      router.push({
        name: 'TemplateDetailsCustomisation',
        params: { templateId },
      });
    };

    return {
      loader,
      loading,
      isLoading,
      onConfigClicked,
      templateId,
    };
  },
});


































































































































































import {
  defineComponent, ref, computed, PropType,
} from '@vue/composition-api';
import Store from '@/store';
import {
  ITemplateSectionField, IFieldConditions, ITemplateConditionalLogic, ISelectFieldOption, ITemplateSectionFieldsValidation,
} from '@/types';
import DateTimePicker from '../DateTimePicker.vue';

export default defineComponent({
  emit: ['selectDate'],
  components: {
    DateTimePicker,
  },
  props: {
    value: {
      type: Object as PropType<ITemplateConditionalLogic>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    currentSectionId: {
      type: Number,
      required: true,
    },
    currentSectionName: {
      type: String,
      required: true,
    },
    firstSelection: {
      type: Boolean,
      required: true,
    },
    selectFieldData: {
      type: Array as PropType<ISelectFieldOption[]>,
      default: () => [],
      required: false,
    },
    sectionFieldData: {
      type: Object as PropType<ITemplateSectionFieldsValidation>,
      default: () => {},
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const selectRef = ref();
    const minValue = ref(1);
    const maxValue = ref(100);
    const operatorsAvailable = ref();
    const fieldType = ref('');
    const selectBoxValues = ref();
    const checkboxValues = ['Checked', 'Unchecked'];
    const conditionalLogic = computed(
      () => props.value.conditionalFields.find((item) => item.id === props.currentSectionId),
    );
    const dateValue = ref();

    const fieldOperators = computed(() => {
      const allFieldConditions = Store.getters['templateCustomisation/getFieldConditions'] as IFieldConditions;
      allFieldConditions.date = allFieldConditions?.date.filter((condition) => !condition.value.includes('xdays'));
      return allFieldConditions;
    });

    /* updates the conditional logic field 'field' with value entered
    & also selects the operators available with that field */
    const onFieldSelected = () => {
      if (conditionalLogic.value?.field) {
        fieldType.value = conditionalLogic.value?.field.type ? conditionalLogic.value?.field.type : '';
        const conLogic = props.value.conditionalFields.find(
          (condition) => condition.id === props.currentSectionId,
        );
        /**
         * access the validation saved on a field to include in conditional logic
         * props.sectionFieldData is the original validation with no edits
         */
        minValue.value = props.sectionFieldData[conLogic?.field.id || 0].validation.min || 1;
        maxValue.value = props.sectionFieldData[conLogic?.field.id || 0].validation.max || 100;

        if (conditionalLogic.value?.field && conLogic) conditionalLogic.value.field = conLogic.field;
      }

      if (fieldType.value) {
        const fields = Store.getters['templateCustomisation/getSectionSelectFieldData']({
          fieldId: conditionalLogic.value?.field.id,
          tempId: conditionalLogic.value?.field.tempId,
        });

        operatorsAvailable.value = fieldOperators.value[fieldType.value];

        selectBoxValues.value = fields.structure;

        if (!fields.structure) {
          selectBoxValues.value = props.selectFieldData;
        }
      }
      if (conditionalLogic.value) {
        /* if field changes conditions and operators must be cleared
          as they could contain values no longer permitted */
        conditionalLogic.value.value = '';
        conditionalLogic.value.condition = {
          text: '',
          value: '',
        };
      }
    };

    /* updates the conditional logic field 'condition' with value entered */
    const onConditionSelected = () => {
      const conLogic = props.value.conditionalFields.find(
        (condition) => condition.id === props.currentSectionId,
      );
      if (conditionalLogic.value?.condition === null) conditionalLogic.value.condition = { text: '', value: '' };
      else if (conditionalLogic.value?.condition.text && conLogic) {
        conditionalLogic.value.condition = conLogic?.condition;
      } else if (conditionalLogic.value) conditionalLogic.value.condition = { text: '', value: '' };
    };

    /**
     *  @summary clearDateField calls method on child component to clear field (only if dateValue)
     *  @author Rory Harford
     */
    const clearFields = () => {
      if (dateValue.value) dateValue.value.onClearField();
      if (selectRef.value) selectRef.value = '';
    };

    /* updates the conditional logic field 'value' with value entered */
    const onValueEntered = () => {
      const conLogic = props.value.conditionalFields.find(
        (condition) => condition.id === props.currentSectionId,
      );

      if (conditionalLogic.value?.value && conLogic) conditionalLogic.value.value = conLogic.value;
      if (conditionalLogic.value?.field.type === 'select') {
        conditionalLogic.value.value = selectRef.value.description;
        conditionalLogic.value.selectCode = selectRef.value.code;
      }
    };

    /* deletes the added extra conditional logic field */
    const deleteExtraCondition = () => {
      const condLogic = props.value.conditionalFields.filter(
        (condition) => condition.id !== props.currentSectionId,
      );
      const logic = props.value;
      logic.conditionalFields = condLogic;
    };

    /**
     *  @summary get all section fields to display when a user is selecting conditional logic
     *  @description function also ensures only saved data and no tableList or jobList shows up in the data
     *  @author Rory Harford
     */
    const jobFieldData = computed(
      (): ITemplateSectionField[] => {
        let fields = Store.getters['templateCustomisation/getAllSectionsFields'];
        fields = fields.filter((field: ITemplateSectionField) => field.type !== 'table' && field.type !== 'joblist' && field.type !== 'subSection' && field.type !== 'notes' && field.id !== -1);
        return fields;
      },
    );

    /* Validation for Length of populate value */
    const rules = {
      lengthRule: (value: string): boolean | string => {
        if (!value) return true;
        return (value.length <= maxValue.value && value.length >= minValue.value) || `Value must be less than ${maxValue.value} and greather then ${minValue.value} in length.`;
      },
      numberValueRule: (value: number): boolean | string => {
        if (!value) return true;
        if (value > maxValue.value && value <= minValue.value) return `Max value is ${maxValue.value} and min value is${minValue.value}.`;
        if (value > maxValue.value) return `Max value is ${maxValue.value}.`;
        if (value < minValue.value) return `Min value is  ${minValue.value}.`;
        return true;
      },
    };

    /**
     * Validation function for number fields, prevents the user from entering any characters
     * other than numbers and one decimal point '.'
     */
    const isNumber = (event: KeyboardEvent, value: string) => {
      if (!/\d/.test(event.key) && (event.key !== '.' || /\./.test(value))) {
        return event.preventDefault();
      }
      return true;
    };

    const onSelectDateValue = (selectedDateValue: string) => {
      if (conditionalLogic.value) {
        conditionalLogic.value.value = selectedDateValue;
        const conLogic = props.value.conditionalFields.find(
          (condition) => condition.id === props.currentSectionId,
        );

        if (conLogic) conLogic.value = selectedDateValue;
      }
    };

    return {
      jobFieldData,
      conditionalLogic,
      deleteExtraCondition,
      fieldOperators,
      onFieldSelected,
      onConditionSelected,
      operatorsAvailable,
      onValueEntered,
      rules,
      checkboxValues,
      isNumber,
      fieldType,
      selectBoxValues,
      onSelectDateValue,
      dateValue,
      clearFields,
      selectRef,
      maxValue,
      minValue,
    };
  },
});






































































































































































































import {
  defineComponent,
  computed,
  ref,
} from '@vue/composition-api';
import draggable from 'vuedraggable';
import router from '@/router';
import Store from '@/store';
import { IWorkorderTemplateData } from '@/types';
import TemplateCustomisationProgressBar from '../components/TemplateCustomisation/TemplateCustomisationProgressBar.vue';
import TemplateCustomisationSectionPanel from '../components/TemplateCustomisation/TemplateCustomisationSectionPanel.vue';
import TemplateCustomisationNavPanel from '../components/TemplateCustomisation/TemplateCustomisationNavPanel.vue';
import StatusRibbonConfigDialog from '../components/TemplateCustomisation/ConfigPopupDialogs/StatusRibbonConfigDialog.vue';
import SaveTemplateChangesDialog from '../components/TemplateCustomisation/SaveTemplateChangesDialog.vue';

export default defineComponent({
  components: {
    StatusRibbonConfigDialog,
    TemplateCustomisationProgressBar,
    TemplateCustomisationSectionPanel,
    TemplateCustomisationNavPanel,
    SaveTemplateChangesDialog,
    draggable,
  },

  setup() {
    const fab = false;
    const fling = false;
    const hover = false;
    const tabs = null;
    const tooltipShow = true;
    const transition = 'slide-y-reverse-transition';
    const { templateId } = router.app.$route.params;
    const { version } = router.app.$route.params;
    const isDragAllowed = ref(true);

    const isMobileView = ref(false);

    /**
       * Disable draggable feature if user on mobile
       */
    const onResize = () => {
      isMobileView.value = window.innerWidth <= 600;
    };

    /**
       * Return loading variable to show/hide skeleton html loaders
       */
    const isLoading = computed(
      () => Store.getters['templateCustomisation/getLoading'] as boolean,
    );

    /**
       * Return loading variable to show/hide skeleton html loaders
       */
    const isConditionalLogicLoading = computed(
      () => Store.getters['conditionLogic/getLoading'] as boolean,
    );

    /**
       * Return template data for customisation
       */
    const templateData = computed(
      () => Store.getters['templateCustomisation/getData'] as IWorkorderTemplateData,
    );

    /**
       * Returns a boolean for isLoading / the published status of a template
       */
    const disabled = computed(
      () => isLoading.value
          || (templateData.value && templateData.value.published),
    );

    /**
       * Allow user to drag section if section name not being edited
       */
    const allowDrag = (value: boolean) => {
      isDragAllowed.value = value;
    };

    /**
       * Sets our template ID in our store when user opens dialog
       */
    const setTemplateData = () => {
      Store.dispatch('templateCustomisation/setTemplateId', templateId);
      Store.dispatch('templateCustomisation/setTemplateVersion', version);
      Store.dispatch('templateStatus/setTemplateId', templateId);
      Store.dispatch('templateMapping/setWorkOrderTemplate', templateId);
      Store.dispatch('templateMapping/loadTemplateData');
    };

    /**
       * Call API to receive template data into vuex
       */
    const getTemplateData = () => {
      setTemplateData();
      Store.dispatch('templateCustomisation/loadFieldConditions');
      Store.dispatch('templateCustomisation/loadData');
      Store.dispatch('templateStatus/loadData');
      Store.dispatch('conditionalLogic/loadConditionalLogicForStatus', templateId);
      Store.dispatch('conditionalLogic/loadConditionalLogicForFields', templateId);
    };

    /**
     *  @summary opens the template creation wizard where user can edit/view details on the template
     *  @description
     *      Depenping if the template is published/unpublished clicking the config will lead to different screens.
     *      IF published the 'config' button will lead to the review screen where user can see all details but make no edits.
     *      IF unpublished the user will be presented to the template create wixard screen on screen '2', this is the screen
     *      after they have added the template name. User will be able to edit all details on the template.
     *  @author Rory Harford
     */
    const onConfigClicked = () => {
      router.push({
        name: 'TemplateDetailsCustomisation',
        params: { templateId },
      });
    };

    getTemplateData();

    onResize();

    return {
      fab,
      fling,
      hover,
      tabs,
      onResize,
      allowDrag,
      isDragAllowed,
      isMobileView,
      tooltipShow,
      transition,
      templateData,
      isLoading,
      disabled,
      onConfigClicked,
      isConditionalLogicLoading,
    };
  },
});










































































































































import {
  defineComponent, ref, reactive, computed, PropType, watch,
} from '@vue/composition-api';
import Store from '@/store';
import { ITemplateSectionField, VForm } from '@/types';
import InboundOutboundFields from './InboundOutboundFields.vue';
// import ConditionalLogicDialog from './ConditionalLogicDialog.vue';

export default defineComponent({
  components: {
    InboundOutboundFields,
    // ConditionalLogicDialog,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    field: {
      type: Object as PropType<ITemplateSectionField>,
      default: () => {},
      required: true,
    },
    sectionName: {
      type: String,
      required: true,
    },
    isPublished: {
      type: Boolean,
      required: true,
    },
    subsectionIndex: {
      type: Number,
      required: false,
    },
  },

  setup(props) {
    const form = ref(null as unknown as VForm);

    const dialog = ref(false);
    const conditonalLogicDialog = ref();

    const tab = null;

    // Dialog activation
    const sectionFieldName = props.sectionName;

    // TODO remove ID key when updating validation
    const fieldData = reactive({
      fieldId: '',
      id: 0,
      inboundMapping: '',
      outboundMapping: '',
      name: '',
      value: '',
      required: false,
      type: '',
      readonly: false,
      validation: {
        fileType: ['Any'],
      },
    }) as ITemplateSectionField;

    watch(dialog, () => {
      if (dialog.value === false && conditonalLogicDialog.value) conditonalLogicDialog.value.clearFields();
    });

    /**
     * Method triggered when user opens dialog
     * searches field data based off index position
     * returns field data stored and assigns it to main field data obj
     */
    const getFieldData = () => {
      const fieldSavedData = Store.getters['templateCustomisation/getSectionFieldData']({
        sectionName: sectionFieldName,
        subsectionIndex: props.subsectionIndex,
        fieldIndex: props.index,
      }) as ITemplateSectionField;

      Object.keys(fieldSavedData).forEach((key) => {
        fieldData[key] = fieldSavedData[key];
      });

      if (!fieldSavedData.validation) return;
      Object.keys(fieldSavedData.validation).forEach((key) => {
        if (fieldSavedData.validation && fieldData.validation) {
          fieldData.validation[key] = fieldSavedData.validation[key];
        }
      });
    };

    /**
     * Closes dialog box
     */
    const closeDialog = () => {
      getFieldData();
      dialog.value = false;
    };

    // Validation for Length of Label
    const rules = {
      lengthRule: (v: string) => (v && v.length <= 100) || 'Label must be less than 100 characters',
    };

    /**
     * Returns title card text depending on published status
     */
    const getCardTitleText = computed(() => {
      let title = `Configure '${fieldData.name}' ${props.field ? props.field.type : ''} Component`;
      if (props.isPublished) title = title.replace('Configure', 'View');
      return title;
    });

    /**
     * Return cancel button text depending on published status
     */
    const getCloseBtnText = computed(() => (props.isPublished ? 'Close' : 'Cancel'));

    const fileOptions = [
      'any',
      'image',
      'document',
      'pdf',
      'spreadsheet',
      'text',
    ];

    /**
     * On Date Type change updates validation and isDateType
     */
    const onChangeFileType = (value: string[]) => {
      if (value && fieldData.validation) {
        const filteredFileType = value.filter((fileType: string) => fileOptions.includes(fileType));
        fieldData.validation.fileType = filteredFileType;
      }
    };

    /**
     * Save updated data into vuex
     */
    const saveValues = () => {
      // Dont allow user to confirm if form validation fails
      if (!form.value.validate()) return;

      const fieldDetails = {
        sectionName: sectionFieldName,
        index: props.index,
        subsectionIndex: props.subsectionIndex,
        fieldData,
      };

      Store.dispatch(
        'templateCustomisation/updateIndividualField',
        fieldDetails,
      );
      dialog.value = false;
    };

    return {
      form,
      rules,
      dialog,
      fieldData,
      getFieldData,
      sectionFieldName,
      closeDialog,
      saveValues,
      fileOptions,
      onChangeFileType,
      getCardTitleText,
      getCloseBtnText,
      tab,
      conditonalLogicDialog,
    };
  },
});

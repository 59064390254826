
























































































































































































































































































































































































































































































import {
  defineComponent, computed, ref, onMounted, onUpdated,
} from '@vue/composition-api';
import draggable from 'vuedraggable';
import _ from 'lodash';
import Store from '@/store';
import { ITemplateSection, ITemplateSectionField, ITemplateSectionFieldsValidation } from '@/types';
import ActionButtons from './TemplateCustomisationActionButtons.vue';
import NewSectionDialog from './ConfigPopupDialogs/NewSectionDialog.vue';

export default defineComponent({
  components: {
    ActionButtons,
    draggable,
    NewSectionDialog,
  },
  props: {
    isPublished: {
      type: Boolean,
      required: true,
    },
    templateName: {
      type: String,
      default: '',
    },
    version: {
      type: Number,
      default: 1,
    },
    sectionCount: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const tab = ref(0);
    const originalValidationValues = ref();

    const draggableGroup = {
      name: 'all-items',
      put: false,
      pull: 'clone',
    };

    const tableHeaders = [
      { text: '', value: 'first' },
      { text: '', value: 'second' },
    ];

    const tableItems = [
      {
        first: '', second: '',
      },
    ];

    /**
       * Following components are structured so that they can easily slot
       * into the section arrays, display the name field and can also read their 'type'
       */
    const textComponent = [
      {
        name: 'Text Field',
        type: 'text',
        inboundMapping: null,
      },
    ];

    const subsecComponent = [
      {
        name: 'Subsection',
        type: 'subSection',
        inboundMapping: null,
        fields: [],
      },
    ];

    const numberComponent = [
      {
        name: 'Number Field',
        type: 'number',
        inboundMapping: null,
      },
    ];

    const noteComponent = [
      {
        name: 'Notes Field',
        type: 'notes',
        inboundMapping: null,
      },
    ];

    const selectComponent = [
      {
        name: 'Select Field',
        type: 'select',
        inboundMapping: null,
      },
    ];

    const dateTimeComponent = [
      {
        name: 'Date/Time Field',
        type: 'datetime',
        inboundMapping: null,
      },
    ];

    const dateComponent = [
      {
        name: 'Date Field',
        type: 'date',
        inboundMapping: null,
      },
    ];

    const checkboxComponent = [
      {
        name: 'Checkbox Field',
        type: 'checkbox',
        inboundMapping: null,
      },
    ];

    const richtextComponent = [
      {
        name: 'RichText Field',
        type: 'richtext',
        inboundMapping: null,
      },
    ];

    const fileComponent = [
      {
        name: 'File Upload',
        type: 'file',
        inboundMapping: null,
      },
    ];

    const tableComponent = [
      {
        name: 'Table',
        type: 'table',
        inboundMapping: null,
      },
    ];

    const jobComponent = [
      {
        name: 'Job List',
        type: 'joblist',
        inboundMapping: null,
      },
    ];

    const itemDropped = ref(false);

    const onAdd = () => {
      itemDropped.value = true;

      setTimeout(() => {
        itemDropped.value = false;
      }, 700);
    };

    /**
     *  @summary Creates a unique id for each component dragged into section
     *  @description This will be used when retrieving data from vuex if field doesnt have db id yet
     *  @author EJ McVey
     */
    const onClone = (e:any) => {
      const newClone = _.cloneDeep(e);
      newClone.tempId = Math.floor(100000 + Math.random() + Math.random() * 5000000);
      return newClone;
    };

    /**
     *  @summary Shows the animated delete box when item in section is dragged
     *  @returns {Boolean}
     *  @author EJ McVey
     */
    const showElement = computed(() => Store.getters['templateCustomisation/getDeleteBoxState']);

    /**
     *  @summary Checks to see if Job List table already exists
     *  @description Can only have 1 Job List table per Template
     *  @returns {Boolean}
     *  @author EJ McVey
     */
    const jobComponentAdded = computed((): boolean => {
      const data = Store.getters['templateCustomisation/getAllSectionsFields'];

      return data ? !!data.find((field: ITemplateSectionField) => field.type === 'joblist') : false;
    });

    onMounted(() => {
      if (props.isPublished) tab.value = 1;
    });

    onUpdated(() => {
      if (props.isPublished) tab.value = 1;
    });

    /**
     *  @summary  This method is called on loading of templates
     * it is used to save the original Validation before the user has made unsaved changes.
     *  @author Rory Harford
     */
    const loadOriginalValidation = () => {
      const fieldsValidation = {} as ITemplateSectionFieldsValidation;
      const tempData = Store.getters['templateCustomisation/getData'];
      if (!tempData) return;
      const sectionsData = tempData.sections;
      sectionsData.forEach((section: ITemplateSection) => {
        section.fields.forEach((field: ITemplateSectionField) => {
          const valFieldId = field.id || 0;
          fieldsValidation[valFieldId] = {
            validation: { ...field.validation },
          };
        });
      });
      originalValidationValues.value = fieldsValidation;
    };
    loadOriginalValidation();

    return {
      tab,
      tableHeaders,
      tableItems,
      draggableGroup,
      subsecComponent,
      textComponent,
      numberComponent,
      noteComponent,
      selectComponent,
      dateComponent,
      dateTimeComponent,
      checkboxComponent,
      fileComponent,
      tableComponent,
      jobComponent,
      showElement,
      itemDropped,
      jobComponentAdded,
      originalValidationValues,
      richtextComponent,
      onAdd,
      onClone,
    };
  },
});





















































































import {
  defineComponent,
  ref,
  reactive,
  computed,
  PropType,
} from '@vue/composition-api';
import Store from '@/store';
import {
  ITemplateSectionField, VForm,
} from '@/types';

export default defineComponent({
  props: {
    index: {
      type: Number,
      required: true,
    },
    field: {
      type: Object as PropType<ITemplateSectionField>,
      default: () => {},
      required: true,
    },
    sectionName: {
      type: String,
      required: true,
    },
    isPublished: {
      type: Boolean,
      required: true,
    },
  },

  setup(props) {
    const form = ref(null as unknown as VForm);

    const dialog = ref(false);

    const sectionFieldName = props.sectionName;

    const fieldData = reactive({
      fieldId: '',
      id: 0,
      inboundMapping: '',
      outboundMapping: '',
      name: '',
      value: '',
      required: false,
      type: '',
      readonly: false,
    }) as ITemplateSectionField;

    const tableHeaders = [
      { text: 'Column Name', value: 'text', width: '80%' },
      {
        text: '', value: 'displayAsHeader', sortable: false, align: 'right',
      },
    ];

    /**
     * Method triggered when user opens dialog
     * searches field data based off index position
     * returns field data stored and assigns it to main field data obj
     */
    const getFieldData = () => {
      const fieldSavedData = Store.getters['templateCustomisation/getSectionFieldData']({
        sectionName: sectionFieldName,
        fieldIndex: props.index,
      }) as ITemplateSectionField;

      Object.keys(fieldSavedData).forEach((key) => {
        fieldData[key] = fieldSavedData[key];
      });
    };

    /**
     * Closes dialog box
     */
    const closeDialog = () => {
      getFieldData();
      dialog.value = false;
    };

    // Validation for Length of Label rule
    const rules = {
      lengthRule: (v: string) => (v && v.length <= 100) || 'Label must be less than 100 characters',
    };

    /**
     * Returns title card text depending on published status
     */
    const getCardTitleText = computed(() => {
      let title = `Configure '${fieldData.name}' ${props.field ? props.field.type : ''} Component`;
      if (props.isPublished) title = title.replace('Configure', 'View');
      return title;
    });

    /**
     * Return cancel button text depending on published status
     */
    const getCloseBtnText = computed(() => (props.isPublished ? 'Close' : 'Cancel'));

    /**
     * Save updated data into vuex
     */
    const saveValues = () => {
      // Dont allow user to confirm if form validation fails
      if (!form.value.validate()) return;

      const fieldDetails = {
        sectionName: sectionFieldName,
        index: props.index,
        fieldData,
      };

      Store.dispatch(
        'templateCustomisation/updateIndividualField',
        fieldDetails,
      );

      dialog.value = false;
    };

    return {
      form,
      rules,
      dialog,
      closeDialog,
      fieldData,
      tableHeaders,
      saveValues,
      getFieldData,
      sectionFieldName,
      getCardTitleText,
      getCloseBtnText,
    };
  },
});

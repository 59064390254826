

















































































import { defineComponent, PropType } from '@vue/composition-api';
import { ITemplateStatus } from '@/types';

export default defineComponent({
  props: {
    statuses: {
      type: Array as PropType<ITemplateStatus[]>,
      default: () => [],
    },
    currentStatus: {
      type: Object as PropType<ITemplateStatus>,
      default: () => {},
    },
  },
  setup() {
  },
});






















































































































































































import { defineComponent, ref, computed } from '@vue/composition-api';
import draggable from 'vuedraggable';
import Store from '@/store';
import {
  IJobListHeader, ITemplateSection, ITemplateSectionField, ITemplateSectionFieldsValidation,
} from '@/types';
import router from '@/router';
import DeleteSectionDialog from './TemplateCustomisationDeleteSectionDialog.vue';
import SubsectionConfigDialog from './ConfigPopupDialogs/SubsectionConfigDialog.vue';
import TemplateCustomisationSectionFields from './TemplateCustomisationSectionFields.vue';

export default defineComponent({
  components: {
    draggable,
    DeleteSectionDialog,
    SubsectionConfigDialog,
    TemplateCustomisationSectionFields,
  },
  props: {
    sectionName: {
      type: String,
      required: true,
      default: '',
    },
    isFirst: {
      type: Boolean,
    },
    isPublished: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  emits: ['dragAllowed'],
  setup(props, { emit }) {
    const panel = ref(props.isFirst ? 0 : undefined);
    const openInputPanel = ref(false);
    const currentSectionName = ref(props.sectionName);
    const getSectionNames = computed((): string[] => Store.getters['templateCustomisation/getSectionNames']);
    const { templateId } = router.app.$route.params;

    const originalValidationValues = ref();

    const rules = [
      (value: string) => !!value || 'Required.',
      (v: string) => (v && v.length <= 100)
          || 'Section name must be less than 100 characters',
      (v: string) => (!getSectionNames.value.includes(v.trim()) || v === props.sectionName) || 'Section name must be unique',
    ];
    const dateTypes = ['date', 'datetime'];

    // Will eventually hold 'materialList', 'ilosList', 'plantList'
    const tableTypes = ['table', 'joblist', 'subSection'];

    /**
     * @summary Get the field size based on the value saved against the field for small and medium screens
     * @return { number } number of columns the field will take up
     * @author Ewa Murjas
     */
    const getSmallMediumValue = (field: ITemplateSectionField, screenSize: string) => {
      if (tableTypes.includes(field.type) || field.size === 'large') return 12;
      if (field.size === 'medium') return screenSize === 'small' ? 9 : 8;
      return screenSize === 'small' ? 6 : 4;
    };

    /**
     * @summary Get the field size based on the value saved against the field for large and extra large screens
     * @return { number } number of columns the field will take up
     * @author Ewa Murjas
     */
    const getLargeValue = (field: ITemplateSectionField, screenSize: string) => {
      if (tableTypes.includes(field.type) || field.size === 'large') return 12;
      if (field.size === 'medium') return 6;
      return screenSize === 'xlarge' ? 2 : 3;
    };

    /**
       * Open the Edit input bar to allow user to Edit Section name
       */
    const openEditInputBar = () => {
      openInputPanel.value = true;
      emit('dragAllowed', false);
    };

    /**
       * Save any edits the user has made
       */
    const saveSectionName = () => {
      Store.dispatch('templateCustomisation/updateSectionName', {
        oldName: props.sectionName,
        newName: currentSectionName.value,
      });
      openInputPanel.value = false;
      emit('dragAllowed', true);
      panel.value = undefined;
    };

    /**
       * FN to control the open close functionality of the panel
       * when user clicks on down arrow icon
       */
    const onOpenCloseClick = () => {
      panel.value = panel.value === 0 ? undefined : 0;
    };
    /**
     *  @summary  This method is called on loading of templates
     * it is used to save the original Validation before the user has made unsaved changes.
     *  @author Rory Harford
     */
    const loadOriginalValidation = () => {
      const fieldsValidation = {} as ITemplateSectionFieldsValidation;
      const sectionsData = Store.getters['templateCustomisation/getData'].sections;
      sectionsData.forEach((section: ITemplateSection) => {
        section.fields.forEach((field: ITemplateSectionField) => {
          const valFieldId = field.id || 0;
          fieldsValidation[valFieldId] = {
            validation: { ...field.validation },
          };
          if (field.type === 'subSection' && field.fields) {
            field.fields.forEach((subField: ITemplateSectionField) => {
              const subvalFieldId = subField.id || 0;
              fieldsValidation[subvalFieldId] = {
                validation: { ...subField.validation },
              };
            });
          }
        });
      });
      originalValidationValues.value = fieldsValidation;
    };

    loadOriginalValidation();

    /**
       * Return correct icon for File Upload field depending on fileType
       */
    const getFileIcon = (field: ITemplateSectionField) => (field.validation && field.validation.fileType === 'Image' ? 'mdi-camera' : 'mdi-paperclip');
    /**
       * Computed function returning from vuex the fields within a given section
       * Sends section name (from props) to vuex and returns data
       * Also maps the setter to update that sectionfields directly
       */
    const sectionFieldData = computed({
      get() {
        const { fields } = Store.getters['templateCustomisation/getSectionFields'](
          currentSectionName.value,
        );
        return fields as ITemplateSectionField[];
      },
      set(value: ITemplateSectionField[]) {
        // manual reset of the order prop based on actual array order.
        value.map((field, index) => {
          field.order = index + 1;
          return field;
        });

        Store.dispatch('templateCustomisation/updateSectionFields', {
          name: currentSectionName.value,
          fields: value,
        });
      },
    });

    const jobListHeaders = computed({
      get() {
        if (sectionFieldData.value && sectionFieldData.value.find((field: ITemplateSectionField) => field.type === 'joblist')) {
          const jobListField = sectionFieldData.value.find((field: ITemplateSectionField) => field.type === 'joblist');
          if (jobListField?.jobListStructure) {
            return jobListField.jobListStructure.slice(0, 3);
          }
          if (jobListField?.structure) {
            return jobListField.structure.slice(0, 3);
          }
          return [
            { text: '', value: 'first' },
            { text: '', value: 'second' },
            { text: '', value: 'third' },
          ];
        }
        return [
          { text: '', value: 'first' },
          { text: '', value: 'second' },
          { text: '', value: 'third' },
        ];
      },
      set(value) {
        return value;
      },
    });

    const updateCurrentHeaders = (updatedHeaders: IJobListHeader[]) => {
      jobListHeaders.value = updatedHeaders;
    };

    /**
       * Computed Fn for the draggable properties
       */
    const dragOptions = computed(() => ({
      animation: 200,
      group: 'all-items',
      disabled: false,
      ghostClass: 'ghost',
    }));

    const isMobileView = ref(false);

    /**
       * Disable draggable feature if user on mobile
       */
    const onResize = () => {
      isMobileView.value = window.innerWidth <= 600;
    };

    const onStart = () => {
      Store.dispatch('templateCustomisation/updateDeleteBox', true);
    };

    const onEnd = () => {
      setTimeout(() => {
        Store.dispatch('templateCustomisation/updateDeleteBox', false);
      }, 500);
    };

    onResize();

    return {
      panel,
      rules,
      dateTypes,
      tableTypes,
      jobListHeaders,
      isMobileView,
      dragOptions,
      sectionFieldData,
      openInputPanel,
      currentSectionName,
      templateId,
      originalValidationValues,
      onResize,
      getFileIcon,
      openEditInputBar,
      saveSectionName,
      onOpenCloseClick,
      onStart,
      onEnd,
      updateCurrentHeaders,
      getSmallMediumValue,
      getLargeValue,
    };
  },
});








































































import {
  defineComponent,
  ref,
  PropType,
  watch,
  computed,
} from '@vue/composition-api';
import { ITemplateSectionFieldsValidation } from '@/types';
import Store from '@/store';
import ConditionalLogicDialog from './TemplateCustomisation/ConfigPopupDialogs/ConditionalLogicDialog.vue';

export default defineComponent({
  components: {
    ConditionalLogicDialog,
  },
  props: {
    sectionFieldData: {
      type: Object as PropType<ITemplateSectionFieldsValidation>,
      default: () => {},
      required: true,
    },
    isPublished: {
      type: Boolean,
      required: true,
    },
    templateId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const dialog = ref(false);
    const apisCalled = ref(false);
    const tab = null;
    const conditionalLogic = ref();
    const openDialog = () => {
      dialog.value = true;
    };

    const closeDialog = () => {
      Store.commit('conditionalLogic/resetConditionalLogicAlarm');
      dialog.value = false;
    };
    const isLoading = computed(() => Store.getters['conditionalLogic/getLoading']);
    /*
    * apisCalled is set when the user has hit 'confirm' and is adding values to the field
    * when the loading is finished and the we closeDialog() and reset value
    */
    watch(isLoading, () => {
      if (!isLoading.value && apisCalled.value) {
        closeDialog();
        apisCalled.value = false;
      }
    });
    watch(dialog, () => {
      if (dialog.value === false && conditionalLogic.value) conditionalLogic.value.clearFields(true);
    });

    const saveValues = () => {
      apisCalled.value = true;
      /*
      * if there is no conditional logic to delete or add we can straight away close the dialog
      * if there is the dialog stays open and we fire delete alarms
      */
      if (Store.getters['conditionalLogic/getDeletedConditionalLogicIds'].length === 0 && Store.getters['conditionalLogic/getAddedConditionalLogic'].length === 0) { closeDialog(); } else {
        Store.dispatch('conditionalLogic/deleteAlarms', {
          workOrderTemplateId: props.templateId,
          calledFromStatusLogic: true,
        });
      }
    };

    return {
      tab,
      dialog,
      openDialog,
      closeDialog,
      saveValues,
      conditionalLogic,
      isLoading,
    };
  },
});










































































import {
  defineComponent, computed, ref, PropType,
} from '@vue/composition-api';
import { ITemplateStatus } from '@/types';
import ProgressBarArrowSVG from './ProgressArrowSVG.vue';

export default defineComponent({
  components: {
    ProgressBarArrowSVG,
  },
  props: {
    statuses: {
      type: Array as PropType<ITemplateStatus[]>,
      default: () => [],
    },
    currentStatus: {
      type: Object as PropType<ITemplateStatus>,
      default: () => {},
    },
  },
  setup(props) {
    const showFirstStatuses = ref(false);
    const showLastStatuses = ref(false);

    const getStepName = (stepName: string) => (stepName.length > 18 ? `${stepName.slice(0, 18)}...` : stepName);

    const currentIndex = props.statuses.findIndex((status) => status.id === props.currentStatus.id);
    const firstStatuses = computed(() => {
      if (currentIndex < 3) return props.statuses.slice(0, currentIndex);

      return props.statuses.slice(0, currentIndex);
    });
    const lastStatuses = computed(() => (props.statuses.slice(currentIndex + 1)));

    return {
      getStepName,
      firstStatuses,
      lastStatuses,
      showFirstStatuses,
      showLastStatuses,
    };
  },
});

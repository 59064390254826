






















































































































import {
  defineComponent,
  ref,
  computed,
} from '@vue/composition-api';
import Store from '@/store';
import { VForm } from '@/types';
import CircleRibbon from '../StatusRibbons/CircleRibbon.vue';
import ArrowRibbon from '../StatusRibbons/ArrowRibbon.vue';

export default defineComponent({
  components: {
    CircleRibbon,
    ArrowRibbon,
  },
  props: {
    isPublished: {
      type: Boolean,
      required: true,
    },
    ribbonType: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const form = ref(null as unknown as VForm);

    const dialog = ref(false);

    const selectedRibbonType = ref(props.ribbonType);

    const statuses = [
      {
        id: 1, name: 'Status 1', order: 0,
      },
      {
        id: 2, name: 'Status 2', order: 1,
      },
      {
        id: 3, name: 'Status 3', order: 2,
      },
      {
        id: 4, name: 'Status 4', order: 3,
      },
    ];

    const currentStatus = {
      id: 3, name: 'Status 3', order: 2,
    };

    const cancelDialog = () => {
      selectedRibbonType.value = props.ribbonType;
      dialog.value = false;
    };

    /**
     * Return cancel button text depending on published status
     */
    const getCloseBtnText = computed(() => (props.isPublished ? 'Close' : 'Cancel'));

    /**
     * Save updated data into vuex
     */
    const saveValues = () => {
      // Dont allow user to confirm if form validation fails
      if (!form.value.validate()) return;

      Store.dispatch(
        'templateCustomisation/updateRibbonType',
        selectedRibbonType.value,
      );
      dialog.value = false;
    };

    return {
      form,
      dialog,
      cancelDialog,
      saveValues,
      getCloseBtnText,
      statuses,
      currentStatus,
      selectedRibbonType,
    };
  },
});

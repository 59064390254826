
























































































































































import {
  defineComponent,
  ref,
  reactive,
  computed,
  PropType,
  watch,
} from '@vue/composition-api';

import { ISelectFieldOption, ITableFieldColumns, ITableHeader } from '@/types';

export default defineComponent({
  props: {
    selectOptions: {
      type: Array as PropType<(ISelectFieldOption | ITableFieldColumns)[]>,
      default: () => [] as ISelectFieldOption[] | ITableFieldColumns[],
    },
    selectValues: {
      type: Array as PropType<(ISelectFieldOption)[]>,
      default: () => [] as ISelectFieldOption[],
    },
    tableType: {
      type: String,
      default: '',
    },
    headers: {
      type: Array,
      default: () => [] as ITableHeader[],
    },
  },
  emit: ['saveSelectOptions'],
  setup(props, { emit }) {
    const dialog = ref(false);
    const dialogDelete = ref(false);
    const form = ref();
    const originalCodeValue = ref();
    const originalDescValue = ref();

    // Edit variables
    const editedIndex = ref(-1);
    const code = ref('');
    const mainInput = ref('');
    /* on the dialog opening we save what the original code value is */
    watch(dialog, () => {
      if (dialog && props.tableType === 'select') {
        originalCodeValue.value = code.value;
        originalDescValue.value = mainInput.value;
      }
    });

    /**
       * Close fn of New/Edit popup
       * return values to default empty strings
       */
    const close = () => {
      dialog.value = false;
      code.value = '';
      mainInput.value = '';
      editedIndex.value = -1;
      form.value.resetValidation();
    };

    /**
       * Close fn of Delete popup
       */
    const closeDelete = () => {
      dialogDelete.value = false;
    };

    // Validation for length of code/description and check if a value was entered
    const rules = {
      required: (value: string): boolean | string => !!value || 'This field is required',
      lengthRule: (value: string): boolean | string => {
        if (!value) return true;
        if (value && value.length > 100) return 'Max 100 characters';
        return true;
      },
      unique: (value: string) => {
        /* check if value matches the original code value */
        if (value === originalCodeValue.value) return true;
        const alreadyAdded = props.selectValues.find((select : ISelectFieldOption) => select.code.trim().toLowerCase() === value.trim().toLowerCase());
        if (alreadyAdded) return 'Code must be unique';
        return true;
      },
      uniqueVal: (value: string) => {
        if (value === originalDescValue.value) return true;
        const alreadyAdded = props.selectValues.find((select: ISelectFieldOption) => select.description.trim().toLowerCase() === value.trim().toLowerCase());
        if (alreadyAdded) return 'Description must be unique';
        return true;
      },
      validCharacters: (value: string): boolean | string => {
        const regex = /^[a-zA-Z0-9\s_.-]+$/;
        return !!regex.test(value) || 'Alpha numeric characters, hyphens, underscores & spaces only';
      },
    };

    // Table Data
    const selectData = reactive(
      props.selectOptions && props.selectOptions.length
        ? props.selectOptions
        : [] as (ISelectFieldOption | ITableFieldColumns)[],
    );

    const getNewTitle = () => (props.tableType === 'select' ? 'New Item' : 'New Column');

    const getEditTitle = () => (props.tableType === 'select' ? 'Edit Item' : 'Edit Column');

    /**
       * Make dialog popup dynamic for New or Edit actions for Select Component
       */
    const formTitle = computed(() => (editedIndex.value === -1 ? getNewTitle() : getEditTitle()));

    /**
       * User clicks edit button, open edit popup
       * Makes changes and will trigger 'save'
       */
    const editItem = (item: ISelectFieldOption | ITableFieldColumns) => {
      editedIndex.value = selectData.indexOf(item);

      if ('description' in item) {
        mainInput.value = item.description;
        code.value = item.code;
      } else {
        mainInput.value = item.text;
        code.value = item.value as string;
      }

      dialog.value = true;
    };

    /**
       * User clicks delete button, populates 'description' into dialog title
       */
    const deleteItem = (item: ISelectFieldOption | ITableFieldColumns) => {
      if ('description' in item) {
        mainInput.value = item.description || '';
      } else if ('text' in item) {
        mainInput.value = item.text || '';
      }

      editedIndex.value = selectData.indexOf(item);
      dialogDelete.value = true;
    };

    /**
       * User confirms deletion of item, remove from existing array
       * and close dialog
       */
    const deleteItemConfirm = () => {
      selectData.splice(editedIndex.value, 1);
      mainInput.value = '';
      editedIndex.value = -1;

      emit('saveSelectOptions', selectData);
      closeDelete();
    };

    /**
     * returns the css name for the table rows
    */
    const getTableRowClass = () => 'select-table__table-row';

    /**
     * Checks if code and description fields are valid
     * @return {boolean} True if valid
     */
    const validateItem = computed((): boolean => {
      let required;
      let validLength;
      let validCharacters;
      let unique;
      let uniqueDesc: string | boolean = true;

      if (props.tableType === 'select') {
        if (!code.value || !mainInput.value) return false;

        unique = rules.unique(code.value);
        uniqueDesc = rules.uniqueVal(mainInput.value);
        required = rules.required(code.value) && rules.required(mainInput.value);
        validLength = rules.lengthRule(code.value) && rules.lengthRule(mainInput.value);
        validCharacters = true;
      } else {
        if (!mainInput.value) return false;
        unique = true;
        required = rules.required(mainInput.value);
        validLength = rules.lengthRule(mainInput.value);
        validCharacters = rules.validCharacters(mainInput.value);
      }

      return (
        typeof unique === 'boolean'
          && unique
          && typeof uniqueDesc === 'boolean'
          && uniqueDesc
          && typeof required === 'boolean'
          && required
          && typeof validLength === 'boolean'
          && validLength
          && typeof validCharacters === 'boolean'
          && validCharacters
      );
    });

    /**
       * Save fn. If new item then pushes into array,
       * if making edits it replaces that item with new values
       * Emits to parent to save against FieldData
       */
    const save = () => {
      if (editedIndex.value > -1) {
        const currentIndex = selectData[editedIndex.value];

        // user editing previously saved item
        if ('code' in currentIndex) {
          currentIndex.code = code.value;
          currentIndex.description = mainInput.value;
        } else {
          currentIndex.value = mainInput.value.replace(/\s+/g, '_').toLowerCase();
          currentIndex.text = mainInput.value;
        }
      } else {
        // this is a new item in table
        const tableObject = {} as ISelectFieldOption | ITableFieldColumns;

        if (props.tableType === 'table') {
          Object.assign(tableObject, {
            value: mainInput.value.replace(/\s+/g, '_').toLowerCase(),
            text: mainInput.value,
          });
        } else {
          Object.assign(tableObject, {
            code: code.value,
            description: mainInput.value,
          });
        }
        selectData.push(tableObject);
      }
      emit('saveSelectOptions', selectData);
      close();
    };

    return {
      dialog,
      dialogDelete,
      code,
      mainInput,
      selectData,
      formTitle,
      save,
      close,
      closeDelete,
      editItem,
      editedIndex,
      deleteItem,
      deleteItemConfirm,
      rules,
      validateItem,
      form,
      getTableRowClass,
    };
  },
});


























































































































































import {
  defineComponent,
  ref,
  reactive,
  computed,
  PropType,
} from '@vue/composition-api';
import draggable from 'vuedraggable';
import _ from 'lodash';
import Store from '@/store';
import {
  ITemplateSectionField, VForm, IJobListHeader, IJobTemplate,
} from '@/types';

export default defineComponent({
  components: {
    draggable,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    field: {
      type: Object as PropType<ITemplateSectionField>,
      default: () => {},
      required: true,
    },
    sectionName: {
      type: String,
      required: true,
    },
    isPublished: {
      type: Boolean,
      required: true,
    },
    subsectionIndex: {
      type: Number,
      required: false,
    },
  },

  setup(props, { emit }) {
    const form = ref(null as unknown as VForm);

    const dialog = ref(false);
    const selectedJobTemplates = ref();

    const jobTemplates = computed(() => {
      const jobs = Store.getters['templateMapping/getTemplateJobs'];
      return jobs.map((job: IJobTemplate) => ({
        text: job.jobTemplateName,
        value: job.jobTemplateId,
      }));
    });

    const sectionFieldName = props.sectionName;

    const fieldData = reactive({
      fieldId: '',
      id: 0,
      inboundMapping: '',
      outboundMapping: '',
      name: '',
      value: '',
      required: false,
      type: '',
      readonly: false,
      jobListStructure: [
        {
          text: 'Job ID',
          value: 'job_id',
          displayAsHeader: true,
          order: 1,
        },
        {
          text: 'Job Template Name',
          value: 'job_template_name',
          displayAsHeader: true,
          order: 2,
        },
        {
          text: 'Job Status',
          value: 'job_status',
          displayAsHeader: true,
          order: 3,
        },
        {
          text: 'Assigned To',
          value: 'assigned_to_name',
          displayAsHeader: true,
          order: 4,
        },
        {
          text: 'Assigned/Planned At',
          value: 'assigned_at',
          displayAsHeader: true,
          order: 5,
        },
        {
          text: 'Job Notes',
          value: 'job_notes',
          displayAsHeader: true,
          order: 6,
        },
        {
          text: 'Sub Contractor',
          value: 'subcontractor',
          displayAsHeader: false,
          order: 10,
        },
      ],
    }) as ITemplateSectionField;

    const jobListStructure = ref(_.cloneDeep(fieldData.jobListStructure as IJobListHeader[]));

    const tableHeaders = [
      { text: 'Column Name', value: 'text', width: '80%' },
      {
        text: '', value: 'displayAsHeader', sortable: false, align: 'right',
      },
    ];

    /**
     * Method triggered when user opens dialog
     * searches field data based off index position
     * returns field data stored and assigns it to main field data obj
     */
    const getFieldData = () => {
      const fieldSavedData = Store.getters['templateCustomisation/getSectionFieldData']({
        sectionName: sectionFieldName,
        subsectionIndex: props.subsectionIndex,
        fieldIndex: props.index,
      }) as ITemplateSectionField;

      Object.keys(fieldSavedData).forEach((key) => {
        fieldData[key] = fieldSavedData[key];
      });

      if (fieldData.structure && fieldData.structure.length > 0) {
        jobListStructure.value = _.cloneDeep(fieldData.structure as IJobListHeader[]);
        jobListStructure.value.sort((a, b) => a.order - b.order);
        delete fieldData.structure;
      }
    };

    /**
     * Send the optional column 'subcontractor' back to the bottom of the list if the checkbox is unchecked
     */
    const checkOrder = (item: IJobListHeader) => {
      if (item.value === 'subcontractor' && !item.displayAsHeader && jobListStructure) {
        jobListStructure.value.push(jobListStructure.value.splice(jobListStructure.value.indexOf(item), 1)[0]);
      }
    };

    /**
     * Disables saving if no options entered
     */
    const optionsSelected = computed(
      () => fieldData.jobListStructure && fieldData.jobListStructure.length,
    );

    /**
     * Closes dialog box
     */
    const closeDialog = () => {
      getFieldData();
      jobListStructure.value = _.cloneDeep(fieldData.jobListStructure as IJobListHeader[]);
      dialog.value = false;
    };

    // Validation for Length of Label rule
    const rules = {
      lengthRule: (v: string) => (v && v.length <= 100) || 'Label must be less than 100 characters',
      required: (v: string) => v.length > 0,
    };

    /**
     * Returns title card text depending on published status
     */
    const getCardTitleText = computed(() => {
      let title = `Configure '${fieldData.name}' ${props.field ? props.field.type : ''} Component`;
      if (props.isPublished) title = title.replace('Configure', 'View');
      return title;
    });

    /**
     * Return cancel button text depending on published status
     */
    const getCloseBtnText = computed(() => (props.isPublished ? 'Close' : 'Cancel'));

    /**
     * Save updated data into vuex
     */
    const saveValues = () => {
      // Dont allow user to confirm if form validation fails
      if (!form.value.validate() || !jobListStructure.value) return;

      // order the columns by their place in the array
      jobListStructure.value.map((column) => Object.assign(column, {
        order: column.displayAsHeader ? jobListStructure.value.indexOf(column) : 10,
      }));

      // save updated structure
      fieldData.jobListStructure = _.cloneDeep(jobListStructure.value);

      fieldData.filterJobTemplatesId = selectedJobTemplates.value.map((job: { text: string, value: number }) => job.value);

      const fieldDetails = {
        sectionName: sectionFieldName,
        index: props.index,
        subsectionIndex: props.subsectionIndex,
        fieldData,
      };

      Store.dispatch(
        'templateCustomisation/updateIndividualField',
        fieldDetails,
      );

      const parentHeaderArray = fieldData.jobListStructure.slice(0, 3);
      emit('updateHeaders', parentHeaderArray);
      dialog.value = false;
    };

    return {
      form,
      rules,
      dialog,
      fieldData,
      tableHeaders,
      jobTemplates,
      sectionFieldName,
      selectedJobTemplates,
      getCardTitleText,
      getCloseBtnText,
      optionsSelected,
      jobListStructure,
      saveValues,
      checkOrder,
      closeDialog,
      getFieldData,
    };
  },
});














































































































































































































import {
  defineComponent,
  PropType,
  computed,
} from '@vue/composition-api';
import router from '@/router';
import {
  ITemplateSectionField,
  IJobListHeader,
} from '@/types';
import TextNumberConfigDialog from './ConfigPopupDialogs/TextNumberConfigDialog.vue';
import SelectFieldConfigDialog from './ConfigPopupDialogs/SelectFieldConfigDialog.vue';
import DateTimeConfigDialog from './ConfigPopupDialogs/DateTimeConfigDialog.vue';
import CheckboxConfigDialog from './ConfigPopupDialogs/CheckboxConfigDialog.vue';
import FileUploadConfigDialog from './ConfigPopupDialogs/FileUploadConfigDialog.vue';
import TableFieldConfigDialog from './ConfigPopupDialogs/TableFieldConfigDialog.vue';
import JobListConfigDialog from './ConfigPopupDialogs/JobListConfigDialog.vue';
import NoteConfigDialog from './ConfigPopupDialogs/NoteConfigDialog.vue';
import RichTextConfigDialog from './ConfigPopupDialogs/RichTextConfigDialog.vue';

export default defineComponent({
  components: {
    TextNumberConfigDialog,
    SelectFieldConfigDialog,
    DateTimeConfigDialog,
    CheckboxConfigDialog,
    FileUploadConfigDialog,
    TableFieldConfigDialog,
    JobListConfigDialog,
    NoteConfigDialog,
    RichTextConfigDialog,
  },
  props: {
    field: {
      type: Object as PropType<ITemplateSectionField>,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    currentSectionName: {
      type: String,
      required: true,
    },
    isPublished: {
      type: Boolean,
      required: true,
    },
    originalValidationValues: {
      type: Object,
      required: true,
    },
    sectionFieldData: {
      type: Array as PropType<ITemplateSectionField[]>,
      required: true,
    },
    subsectionIndex: {
      type: Number,
      required: false,
    },
  },
  setup(props) {
    const { templateId } = router.app.$route.params;

    // Table Config
    const tableHeaders = [
      { text: 'No columns configured', value: 'first' },
    ];

    const tableItems = [
      { first: '', second: '' },
    ];

    const jobListItems = [
      { jobId: '', job_template_name: '', jobStatus: '' },
    ];

    const dateTypes = ['date', 'datetime'];

    const jobListHeaders = computed({
      get() {
        if (props.sectionFieldData && props.sectionFieldData.find((field: ITemplateSectionField) => field.type === 'joblist')) {
          const jobListField: any = props.sectionFieldData.find((field: ITemplateSectionField) => field.type === 'joblist');
          if (jobListField?.jobListStructure) {
            return jobListField.jobListStructure.slice(0, 3);
          }
          if (jobListField?.structure) {
            return jobListField.structure.slice(0, 3);
          }
        }
        return [
          { text: '', value: 'first' },
          { text: '', value: 'second' },
          { text: '', value: 'third' },
        ];
      },
      set(value) {
        return value;
      },
    });

    const updateCurrentHeaders = (updatedHeaders: IJobListHeader[]) => {
      jobListHeaders.value = updatedHeaders;
    };

    /**
     * Return correct icon for File Upload field depending on fileType
     */
    const getFileIcon = (field: ITemplateSectionField) => (field.validation && field.validation.fileType === 'Image' ? 'mdi-camera' : 'mdi-paperclip');

    return {
      templateId,
      tableHeaders,
      tableItems,
      jobListHeaders,
      jobListItems,
      dateTypes,
      updateCurrentHeaders,
      getFileIcon,
    };
  },
});

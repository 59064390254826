


























import {
  defineComponent, computed, watch, ref,
} from '@vue/composition-api';
import _ from 'lodash';
import Store from '@/store';
import { IWorkorderTemplateData, ITemplateStatus } from '@/types';
import CircleRibbon from './StatusRibbons/CircleRibbon.vue';
import ArrowRibbon from './StatusRibbons/ArrowRibbon.vue';

export default defineComponent({
  components: {
    CircleRibbon,
    ArrowRibbon,
  },
  setup() {
    /**
       * Return loading variable to show/hide skeleton html loaders
       */
    const isLoading = computed(
      () => Store.getters['templateCustomisation/getLoading'] as boolean,
    );

    const isConfigLoading = computed(() => Store.getters['workOrderTemplate/getLoading'] as boolean);

    const statusReloading = ref(false);

    /**
     * Return template data for customisation
     */
    const templateData = computed(
      () => Store.getters['templateCustomisation/getData'] as IWorkorderTemplateData,
    );

    const templateStatuses = computed(() => Store.getters['templateStatus/getData'] as ITemplateStatus[]);

    const statuses = computed(() => {
      if (templateStatuses.value) {
        const clonedStatuses = _.cloneDeep(templateStatuses.value);
        return clonedStatuses.sort((a, b) => a.order - b.order);
      }
      return [];
    });

    const currentStatus = computed(() => {
      const statusInfo = statuses.value.find((status) => status.order === 0);
      if (statusInfo) return statusInfo;
      return null;
    });

    const ribbonType = computed(() => {
      if (templateData.value && templateData.value.ribbonType) {
        return templateData.value.ribbonType;
      }
      return 'circle';
    });

    /**
     * After user edits and saves the statuses, the isConfigLoading value will go from true to false
     * Then we can load the status data again to retrieve updated statuses.
     */
    watch(isConfigLoading, async (to, from) => {
      if (!to && from) {
        statusReloading.value = true;
        await Store.dispatch('templateStatus/loadData');
        statusReloading.value = false;
      }
    });

    return {
      statuses,
      currentStatus,
      ribbonType,
      isLoading,
      isConfigLoading,
      statusReloading,
    };
  },
});
